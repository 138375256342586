@media only screen and (max-width: 767px) {
  .navbar-area .navbar-brand {
    padding-top: 0;
  }
  .navbar-area .navbar-brand img {
    max-width: 130px;
  }
  .navbar-area nav {
    background-color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area nav .navbar-nav {
    overflow-y: auto;
    max-height: 70vh;
    padding-right: 15px;
  }
  .navbar-area nav .navbar-nav::-webkit-scrollbar {
    width: 12px;
  }
  .navbar-area nav .navbar-nav::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 30px;
  }
  .navbar-area nav .navbar-nav::-webkit-scrollbar-thumb {
    background: #1dd3f8;
    border-radius: 30px;
  }
  .navbar-area nav .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #1dd3f8;
    border-radius: 30px;
  }
  .navbar-area nav .navbar-nav .nav-item {
    margin-left: 0;
    margin-right: 0;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .navbar-area nav .navbar-nav .nav-item a {
    font-size: 15px;
    margin: 0;
  }
  .navbar-area nav .navbar-nav .nav-item a i {
    display: none;
  }
  .navbar-area nav .navbar-nav .nav-item .dropdown-menu {
    top: 0 !important;
    opacity: 1;
    width: 100%;
    position: relative;
    visibility: visible;
    border: 1px solid #eee;
    margin-bottom: 5px;
    padding: 10px 5px;
    margin-top: 5px;
  }
  .navbar-area nav .navbar-nav .nav-item .dropdown-menu li a {
    font-size: 15px;
  }
  .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    left: 15px;
    width: 90%;
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .navbar-area nav .navbar-nav .nav-item .dropdown-toggle::before {
    display: none;
  }
  .navbar-area .others-options {
    left: 0;
    top: 10px;
    padding-bottom: 10px;
  }
  .navbar-area .others-options .default-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .navbar-area.is-sticky nav {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ptb-100 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-100 {
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 20px;
  }
  .pb-70 {
    padding-bottom: 20px;
  }
  .mt-100 {
    margin-top: 50px;
  }
  .others-option {
    display: none;
  }
  p {
    font-size: 14px;
  }
  h3 {
    font-size: 20px;
    line-height: 1.3;
  }
  .default-btn {
    padding: 14px 20px;
    font-size: 14px;
  }
  .banner-img {
    display: none;
  }
  .section-title {
    margin: -9px auto 40px;
  }
  .section-title h2 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 1.3;
  }
  .business-btn {
    text-align: center;
  }
  .main-banner-area {
    padding-top: 130px;
    padding-bottom: 100px;
  }
  .main-banner-area .banner-text {
    text-align: center;
  }
  .main-banner-area .banner-text h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .main-banner-area .banner-text p {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .main-banner-area .banner-text .banner-btn .default-btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .main-banner-area .banner-main-img {
    display: block;
    margin-top: 30px;
  }
  .main-banner-area .container-fluid {
    max-width: 540px;
  }
  .main-banner-area.main-banner-area-one .banner-text {
    max-width: unset;
    margin-left: unset;
    top: 0;
  }
  .main-banner-area .white-shape {
    animation: unset;
  }
  .single-features {
    margin-left: 15px;
    margin-right: 15px;
    padding: 19px;
  }
  .single-features i {
    margin-bottom: 20px;
  }
  .single-features p {
    margin-bottom: 20px;
  }
  .about-content h2 {
    font-size: 25px;
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .about-content ul {
    margin-bottom: 10px;
  }
  .about-content ul li {
    font-size: 15px;
  }
  .about-content .default-btn {
    margin-top: 20px;
  }
  .about-img {
    margin-bottom: 30px;
    text-align: center;
  }
  .choose-ue-area .col-8 {
    flex: 100%;
    max-width: 100%;
  }
  .choose-title h2 {
    font-size: 25px;
    line-height: 1.3;
  }
  .choose-card {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    text-align: center;
  }
  .choose-card span {
    position: relative;
    top: auto;
    left: auto;
  }
  .choose-card span i {
    top: auto;
    left: 50px;
    bottom: -10px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 30px;
  }
  .choose-img {
    text-align: center;
  }
  .offer-area .offer-shape img:nth-child(2) {
    opacity: 0.3;
  }
  .business-content h2 {
    font-size: 25px;
    margin-bottom: 30px;
    line-height: 1.3;
  }
  .business-area .default-btn {
    margin-top: 0;
  }
  .counter-nth:nth-child(1) .single-counter {
    margin-top: 30px;
  }
  .counter-nth:nth-child(2) .single-counter {
    margin-top: 0px;
  }
  .counter-nth:nth-child(3) .single-counter {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .single-counter h2 span {
    font-size: 40px;
  }
  .single-counter p {
    font-size: 18px;
  }
  .industries-area .industries-list-tab .tabs li span {
    font-size: 13px;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .industries-area .industries-list-tab .tabs li i {
    top: 35px;
  }
  .industries-area .industries-content .industries-item {
    margin-top: 0;
  }
  .industries-area .mb {
    margin-bottom: 25px;
  }
  .industries-area .industries-list-tab .tabs {
    margin: 0 0 25px;
  }
  .industries-img.left-img {
    margin-bottom: 30px;
  }
  .industries-img.right-img {
    margin-top: 30px;
  }
  .single-client {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .footer-bottom-area {
    text-align: center;
  }
  .footer-bottom-area .designed {
    text-align: unset;
  }
  .main-banner-area-two .banner-text {
    top: 0;
  }
  .newsletter-area {
    text-align: center;
  }
  .newsletter-area .default-btn {
    padding: 18px 20px;
    font-size: 14px;
  }
  .newsletter-wrap {
    margin-bottom: 30px;
  }
  .newsletter-wrap h2 {
    font-size: 30px;
  }
  .newsletter-img {
    margin-bottom: 30px;
    text-align: center;
  }
  .call-us h2 {
    font-size: 25px;
  }
  .call-us span {
    font-size: 25px;
  }
  .industries-serve-area {
    padding-bottom: 40px;
  }
  .industries-serve-area .single-industries i {
    top: 36px;
  }
  .industries-serve-area .industries-img {
    margin-bottom: 30px;
  }
  .pb-50 {
    padding-bottom: 0;
  }
  .main-banner-area-three .banner-text .banner-btn .default-btn.active {
    padding-left: 20px;
  }
  .main-banner-area-three .banner-text .banner-btn .default-btn.active i {
    display: none;
  }
  .faq-img {
    text-align: center;
    margin-top: 30px;
  }
  .main-banner-area-four {
    padding-bottom: 70px !important;
  }
  .main-banner-area-four .banner-text .banner-btn .default-btn.active {
    padding-left: 20px;
  }
  .main-banner-area-four .banner-text .banner-btn .default-btn.active i {
    display: none;
  }
  .main-banner-area-four .banner-text {
    top: 0;
  }
  .banner-four-main-img {
    margin-top: 30px;
  }
  .single-team {
    background-color: #060542;
  }
  .jumpx-slider-item .jumpx-slider-text h1 {
    font-size: 35px;
  }
  .jumpx-slider-item .jumpx-slider-text p {
    font-size: 14px;
  }
  .jumpx-slider-item .jumpx-slider-text .slider-btn .default-btn {
    margin: 0 5px;
  }
  .jumpx-slider-item {
    height: 100%;
    padding-top: 115px;
    padding-bottom: 100px;
  }
  .page-title-area {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .page-navigation-area {
    margin: 0px auto 0;
  }
  .faq-accordion .accordion .accordion-title {
    font-size: 16px;
  }
  .ask-question {
    padding: 25px;
  }
  .ask-question h3 {
    text-align: center;
    margin-bottom: 20px !important;
  }
  .page-title-area .page-title-content h2 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .user-area-all-style .contact-form-action {
    padding: 20px;
  }
  .user-area-all-style .contact-form-action .form-heading .form-title {
    font-size: 25px;
  }
  .user-area-all-style .contact-form-action .form-heading .form-desc {
    font-size: 15px;
  }
  .user-area-all-style .contact-form-action .default-btn {
    display: block;
    width: 100%;
  }
  .user-area-all-style .contact-form-action .agree-label {
    text-align: center;
  }
  .user-area-all-style .contact-form-action .forget {
    float: unset;
    float: unset;
    margin-bottom: 30px;
    text-align: center;
    display: block;
  }
  .user-area-all-style .contact-form-action .now-register {
    float: unset;
    margin-top: 10px;
  }
  .features-area-inner-style .single-features {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .service-list-img {
    margin-bottom: 30px;
  }
  .paint {
    margin-bottom: 15px;
  }
  .service-details-wrap .car-service-list-wrap .car-service-list ul li {
    display: block;
  }
  .woocommerce-result-count {
    margin-bottom: 20px;
    text-align: center;
  }
  .order-table.table-responsive {
    margin-top: 30px;
  }
  .widget.widget_tag_cloud {
    margin-bottom: 35px;
  }
  .contact-img {
    margin-top: 30px;
  }
  .contact-info-area .single-contact-info {
    padding: 40px;
  }
  #map {
    height: 250px;
  }
  .main-contact-area .contact-wrap .contact-form {
    padding: 30px;
  }
  .main-contact-area .contact-wrap .contact-form .section-title {
    margin-bottom: 20px;
  }
  .contact-form.contact-form-mb {
    margin-bottom: 30px;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-right: 95px;
  }
  .faq-contact-area .section-title {
    margin-bottom: 20px;
  }
  .error-area {
    padding: 70px 0;
  }
  .error-area .error-content-wrap p {
    margin: 15px 0 20px;
  }
  .error-area .error-content-wrap h1 {
    font-size: 150px;
  }
  .coming-soon-area .coming-soon-content {
    height: 100%;
  }
  .coming-soon-area .coming-soon-content h1 {
    font-size: 40px;
    line-height: 1.1;
    margin-top: 0;
  }
  .coming-soon-area .coming-soon-content p {
    font-size: 13px;
    margin-top: 15px;
  }
  .coming-soon-area .coming-soon-content #timer {
    margin-top: 20px;
  }
  .coming-soon-area .coming-soon-content #timer div {
    font-size: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .coming-soon-area .coming-soon-content #timer div span {
    font-size: 14px;
    margin-top: -5px;
  }
  .coming-soon-area .coming-soon-content #timer div::before {
    display: none;
  }
  .coming-soon-area .coming-soon-content .newsletter-form {
    margin-top: 35px;
  }
  .coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
    height: 55px;
    padding-left: 13px;
    font-size: 15px;
  }
  .coming-soon-area .coming-soon-content .newsletter-form button {
    position: relative;
    height: auto;
    padding: 14px 30px;
    font-size: 14px;
    margin-top: 15px;
  }
  .checkout-area .checkout-area .title {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .checkout-area .billing-details {
    padding: 15px;
  }
  .checkout-area .billing-details .form-group {
    margin-bottom: 15px;
  }
  .checkout-area .billing-details .form-group label {
    margin-bottom: 7px;
  }
  .checkout-area .billing-details .form-group .form-control {
    height: 40px;
    line-height: 40px;
  }
  .checkout-area .billing-details .form-group textarea {
    height: 100% !important;
  }
  .checkout-area .billing-details .form-check label {
    top: 2px;
  }
  .checkout-area .order-details {
    margin-top: 30px;
  }
  .checkout-area .order-details .order-table {
    padding: 15px;
  }
  .checkout-area .order-details .order-table table thead tr th {
    white-space: nowrap;
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
  .checkout-area .order-details .order-table table tbody tr td {
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
  }
  .checkout-area .order-details .payment-box {
    padding: 15px;
  }
  .cart-area .cart-table {
    text-align: center;
  }
  .cart-area .cart-table table thead tr th {
    padding: 0 40px 10px;
    white-space: nowrap;
  }
  .cart-area .cart-table table thead tr th:first-child {
    text-align: left;
    padding-left: 0;
  }
  .cart-area .cart-table table tbody tr td {
    white-space: nowrap;
  }
  .cart-area .cart-table table tbody tr td:first-child {
    text-align: left;
    padding-left: 0;
  }
  .cart-area .cart-buttons {
    text-align: center;
  }
  .cart-area .cart-buttons .text-right {
    text-align: center !important;
    margin-top: 0;
  }
  .cart-area .cart-totals {
    padding: 20px;
    margin-top: 35px;
  }
  .cart-area .cart-totals h3 {
    margin-bottom: 18px;
    font-size: 17px;
  }
  .cart-area .cart-totals ul li {
    font-size: 13px;
  }
  .cart-area .cart-totals .default-btn {
    display: block;
    text-align: center;
  }
  .cart-area .checkout-area .title {
    margin-bottom: 20px;
    font-size: 17px;
  }
  .cart-area .billing-details {
    padding: 15px;
  }
  .cart-area .billing-details .form-group {
    margin-bottom: 15px;
  }
  .cart-area .billing-details .form-group label {
    margin-bottom: 7px;
  }
  .cart-area .billing-details .form-group .form-control {
    height: 40px;
    line-height: 40px;
  }
  .cart-area .billing-details .form-group textarea {
    height: 100% !important;
  }
  .cart-area .billing-details .form-check label {
    top: 2px;
  }
  .cart-area .order-details {
    margin-top: 30px;
  }
  .cart-area .order-details .order-table {
    padding: 15px;
  }
  .cart-area .order-details .order-table table thead tr th {
    white-space: nowrap;
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
  .cart-area .order-details .order-table table tbody tr td {
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
  }
  .cart-area .order-details .payment-box {
    padding: 15px;
  }
  .cart-area .cart-area .default-btn {
    display: block;
    text-align: center;
  }
  .cart-area .coupon-wrap {
    padding: 20px !important;
    margin-top: 30px;
  }
  .cart-area .cart-wraps {
    padding: 20px;
  }
  .cart-area .cart-wraps .continue-shopping-box {
    margin-bottom: 20px;
  }
  .cart-area .cart-wraps .default-btn {
    width: 100%;
  }
  .cart-area .coupon-wrap .default-btn {
    width: 100%;
  }
  .product-details-area .product-details-desc {
    margin-top: 25px;
  }
  .product-details-area .product-details-desc h3 {
    font-size: 16px;
  }
  .product-details-area .product-details-desc .price {
    margin-bottom: 13px;
  }
  .product-details-area .product-details-desc .product-review {
    margin-bottom: 13px;
  }
  .product-details-area .product-details-desc .product-review .rating {
    font-size: 13px;
  }
  .product-details-area .products-details-tab {
    margin-top: 30px;
  }
  .product-details-area .products-details-tab .tabs {
    margin-bottom: -11px;
  }
  .product-details-area .products-details-tab .tabs li {
    margin-bottom: 10px;
  }
  .product-details-area .products-details-tab .tabs li a {
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;
    padding-left: 40px;
  }
  .product-details-area .products-details-tab .tabs li a .dot {
    left: 20px;
  }
  .product-details-area .products-details-tab .tab_content {
    padding: 25px 20px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .additional-information li span {
    display: block;
    width: 100%;
    margin-bottom: 2px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content h3 {
    font-size: 16px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .rating i {
    font-size: 13px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-title .btn {
    position: relative;
    right: 0;
    top: 0;
    transform: unset;
    margin-top: 15px;
    display: block;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item {
    padding-right: 0;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .rating i {
    font-size: 13px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item h3 {
    font-size: 15px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item span {
    font-size: 12px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-comments .review-item .review-report-link {
    position: relative;
    top: 0;
    margin-top: 15px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .review-rating {
    display: none;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .form-group label {
    font-size: 13px;
  }
  .product-details-area .products-details-tab .custom-payment-options .payment-methods a img {
    width: 39px;
  }
  .product-details-area .products-details-tab .tab_content .tabs_item .products-details-tab-content .product-review-form .review-form form .btn {
    width: 100%;
  }
  .news-details-area .blog-details-desc .article-content .entry-meta ul li {
    display: block;
    font-size: 15px;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .news-details-area .blog-details-desc .article-content .entry-meta ul li::before {
    display: none;
  }
  .news-details-area .blog-details-desc .article-content h3 {
    line-height: 1.5;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .news-details-area .blog-details-desc .article-content p {
    font-size: 15px;
  }
  .news-details-area .blog-details-desc .article-content .features-list li {
    font-size: 15px;
  }
  .news-details-area .widget-area .widget {
    padding: 0;
  }
  .news-details-area blockquote {
    padding: 30px !important;
  }
  .news-details-area blockquote p {
    font-size: 16px !important;
  }
  .news-details-area .blockquote {
    padding: 30px !important;
  }
  .news-details-area .blockquote p {
    font-size: 16px !important;
  }
  .news-details-area .comments-area {
    padding: 20px;
  }
  .news-details-area .comments-area .comment-author {
    font-size: 15px;
    margin-bottom: 0.3em;
  }
  .news-details-area .comments-area .comment-author .avatar {
    left: 0;
    position: relative;
    display: block;
    margin-bottom: 10px;
  }
  .news-details-area .comments-area .comment-body {
    padding-left: 0;
  }
  .news-details-area .comments-area .comments-title {
    font-size: 19px;
  }
  .news-details-area .comments-area .comments-title::before {
    left: -20px;
  }
  .news-details-area .comments-area .comment-metadata {
    font-size: 12px;
  }
  .news-details-area .comments-area .comment-respond .comment-reply-title {
    font-size: 19px;
  }
  .news-details-area .comments-area .comment-respond .comment-reply-title::before {
    left: -20px;
  }
  .news-details-area .comments-area .comment-respond .comment-form-author {
    width: 100%;
    padding-right: 0;
  }
  .news-details-area .comments-area .comment-respond .comment-form-email {
    width: 100%;
    padding-left: 0;
  }
  .news-details-area .comments-area .comment-respond .form-submit input {
    padding: 12px 25px 10px;
  }
  .news-details-area .widget-area .widget:first-child {
    margin-top: 35px;
  }
  .tab.quote-list-tab .tabs li {
    font-size: 16px;
  }
  .brand-list .single-brand {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .modal-video-body {
    padding: 0 20px;
  }
  .main-banner-area-four .banner-text {
    padding-right: 0;
  }
  .service-details-wrap .service-img {
    margin-bottom: 20px;
  }
  .service-details-wrap h3 {
    margin-bottom: 10px;
    font-size: 25px;
  }
  .service-sidebar-area {
    margin-top: 30px;
  }
  .user-area-all-style .contact-form-action .form-heading {
    margin-bottom: 20px;
  }
  .single-widget .logo {
    margin-bottom: 15px;
  }
  .single-widget .logo img {
    max-width: 130px;
  }
  .text-container h3 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .page-navigation-area .page-link {
    width: 35px;
    height: 35px;
    font-size: 12px;
  }
  .page-navigation-area .page-link i {
    font-size: 15px;
  }
  .news-details-area .blog-details-desc .article-footer .article-share .social li a {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .cta-area .cta-content h3 {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-banner-area {
    padding-bottom: 150px;
  }
  .choose-card {
    padding-left: 135px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: left;
  }
  .choose-card span {
    position: absolute;
    top: 30px;
    left: 35px;
  }
  .choose-card span i {
    top: 45px;
    left: 40px;
  }
  .single-offer {
    padding-left: 20px;
  }
  .single-offer i {
    position: unset;
    margin-bottom: 20px;
  }
  .counter-nth:nth-child(2) .single-counter {
    margin-top: 30px;
  }
  .counter-nth:nth-child(3) .single-counter {
    margin-bottom: 0;
  }
  .main-banner-area-two {
    padding-top: 150px;
  }
  .single-pricing {
    padding: 20px;
  }
  .user-area-all-style .contact-form-action .forget {
    float: right;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-right: 95px;
  }
  .coming-soon-area .coming-soon-content h1 {
    font-size: 55px;
  }
  .coming-soon-area .coming-soon-content #timer div {
    font-size: 45px;
  }
  .coming-soon-area .coming-soon-content #timer div::before {
    font-size: 65px;
    right: -49px;
  }
  .news-details-area .blog-details-desc .article-content .entry-meta ul li {
    display: inline-block;
    font-size: 15px;
    margin-right: 10px;
  }
  .woocommerce-result-count {
    margin-bottom: 0;
  }
  .continue-shopping-box {
    margin-bottom: 0 !important;
    float: left;
  }
  .contact-info-area .single-contact-info {
    padding: 20px;
  }
  .industries-area .industries-list-tab .tabs li {
    display: inline-block;
    width: 45%;
    margin: 0 10px;
    margin-bottom: 10px;
  }
  .industries-area .industries-list-tab .tabs li:last-child {
    margin-bottom: 10px;
  }
  .industries-area .industries-list-tab .tabs li span {
    padding-right: 20px;
    padding-top: 20px;
    padding-left: 70px;
    padding-bottom: 20px;
  }
  .industries-area .industries-list-tab .tabs li h3 {
    font-size: 18px;
    margin-bottom: 3px;
  }
  .industries-area .industries-list-tab .tabs li i {
    font-size: 30px;
    top: 28px;
    left: 26px;
  }
  .service-card {
    padding: 20px 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-area nav .container {
    padding: 0;
  }
  .navbar-area nav .navbar-brand img {
    max-width: 110px;
  }
  .navbar-area nav .navbar-brand .logo {
    font-size: 22px;
  }
  .navbar-area nav .navbar-nav .nav-item a {
    font-size: 14px;
    margin-left: 6px;
    margin-right: 6px;
  }
  .navbar-area .others-options {
    display: none;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .mt-100 {
    margin-top: 60px;
  }
  .others-option {
    display: none;
  }
  p {
    font-size: 14px;
  }
  h3 {
    font-size: 20px;
  }
  .default-btn {
    padding: 14px 20px;
  }
  .banner-img {
    display: none;
  }
  .section-title {
    margin: -9px auto 40px;
  }
  .section-title h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .main-banner-area {
    padding-top: 130px;
    padding-bottom: 180px;
  }
  .main-banner-area .banner-text {
    text-align: center;
    max-width: unset;
  }
  .main-banner-area .banner-text h1 {
    font-size: 40px;
    margin-bottom: 25px;
  }
  .main-banner-area .banner-text p {
    font-size: 15px;
    margin-bottom: 30px;
  }
  .main-banner-area .banner-main-img {
    display: block;
    margin-top: 30px;
  }
  .main-banner-area .container-fluid {
    max-width: 720px;
  }
  .main-banner-area.main-banner-area-one .banner-text {
    max-width: unset;
    margin-left: unset;
    top: 0;
  }
  .main-banner-area .white-shape {
    animation: unset;
  }
  .single-features {
    margin-left: 15px;
    margin-right: 15px;
    padding: 19px;
  }
  .single-features i {
    margin-bottom: 20px;
  }
  .single-features p {
    margin-bottom: 20px;
  }
  .about-content h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .about-content ul {
    margin-bottom: 10px;
  }
  .about-content ul li {
    font-size: 15px;
  }
  .about-content .default-btn {
    margin-top: 20px;
  }
  .about-img {
    margin-bottom: 30px;
    text-align: center;
  }
  .choose-ue-area .col-8 {
    flex: 100%;
    max-width: 100%;
  }
  .choose-title {
    margin-bottom: 40px;
  }
  .choose-title h2 {
    font-size: 30px;
  }
  .choose-card {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  .choose-card span {
    position: unset;
  }
  .choose-card span i {
    top: 60px;
    left: 70px;
  }
  .choose-img {
    text-align: center;
  }
  .offer-area .offer-shape img:nth-child(2) {
    opacity: 0.3;
  }
  .business-content h2 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .business-area .default-btn {
    margin-top: 0;
  }
  .counter-nth:nth-child(1) .single-counter {
    margin-top: 30px;
  }
  .counter-nth:nth-child(2) .single-counter {
    margin-top: 30px;
  }
  .counter-nth:nth-child(3) .single-counter {
    margin-top: 0px;
    margin-bottom: 0;
  }
  .single-counter h2 span {
    font-size: 40px;
  }
  .single-counter p {
    font-size: 18px;
  }
  .industries-area .industries-list-tab .tabs li span {
    font-size: 13px;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .industries-area .industries-content h3 {
    font-size: 25px;
  }
  .industries-img.left-img {
    margin-bottom: 30px;
  }
  .industries-img.right-img {
    margin-top: 30px;
  }
  .single-client {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .footer-bottom-area {
    text-align: center;
  }
  .footer-bottom-area .designed {
    text-align: unset;
  }
  .main-banner-area-two {
    padding-top: 200px;
  }
  .main-banner-area-two .banner-text {
    top: 0;
  }
  .newsletter-area {
    text-align: center;
  }
  .newsletter-area .default-btn {
    padding: 18px 20px;
    font-size: 14px;
  }
  .newsletter-wrap {
    margin-bottom: 30px;
  }
  .newsletter-wrap h2 {
    font-size: 25px;
  }
  .newsletter-img {
    margin-bottom: 30px;
    text-align: center;
  }
  .call-us h2 {
    font-size: 25px;
  }
  .call-us span {
    font-size: 25px;
  }
  .industries-serve-area {
    padding-bottom: 40px;
  }
  .industries-serve-area .single-industries i {
    top: 36px;
  }
  .industries-serve-area .industries-img {
    margin-bottom: 30px;
  }
  .faq-img {
    margin-top: 30px;
    text-align: center;
  }
  .main-banner-area-three .banner-text .banner-btn .default-btn.active {
    padding-left: 20px;
  }
  .main-banner-area-three .banner-text .banner-btn .default-btn.active i {
    display: none;
  }
  .main-banner-area-four {
    padding-bottom: 70px !important;
  }
  .main-banner-area-four .banner-text .banner-btn .default-btn.active {
    padding-left: 20px;
  }
  .main-banner-area-four .banner-text .banner-btn .default-btn.active i {
    display: none;
  }
  .main-banner-area-four .banner-text {
    top: 0;
  }
  .banner-four-main-img {
    margin-top: 30px;
  }
  .single-team {
    background-color: #060542;
  }
  .jumpx-slider-item .jumpx-slider-text h1 {
    font-size: 35px;
  }
  .jumpx-slider-item .jumpx-slider-text p {
    font-size: 14px;
  }
  .jumpx-slider-item .jumpx-slider-text .slider-btn .default-btn {
    margin: 0 5px;
  }
  .page-title-area {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .page-navigation-area {
    margin: 0px auto 0;
  }
  .faq-accordion .accordion .accordion-title {
    font-size: 16px;
  }
  .ask-question {
    padding: 30px;
  }
  .page-title-area .page-title-content h2 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .user-area-all-style .contact-form-action .form-heading .form-title {
    font-size: 25px;
  }
  .user-area-all-style .contact-form-action .form-heading .form-desc {
    font-size: 15px;
  }
  .features-area-inner-style .single-features {
    margin-bottom: 40px;
    margin-left: 0;
    margin-right: 0;
  }
  .paint {
    margin-bottom: 15px;
  }
  .service-details-wrap .car-service-list-wrap .car-service-list ul li {
    display: block;
  }
  .order-table.table-responsive {
    margin-top: 30px;
  }
  .product-details-image {
    margin-bottom: 30px;
  }
  .widget.widget_tag_cloud {
    margin-bottom: 35px;
  }
  .contact-img {
    margin-top: 30px;
  }
  .contact-info-area .single-contact-info {
    padding: 40px;
  }
  #map {
    height: 250px;
  }
  .main-contact-area .contact-wrap .contact-form {
    padding: 20px;
  }
  .contact-form.contact-form-mb {
    margin-bottom: 30px;
  }
  .industries-area .industries-list-tab .tabs li {
    display: inline-block;
    width: 45%;
    margin: 0 15px;
    margin-bottom: 10px;
  }
  .industries-area .industries-list-tab .tabs li:last-child {
    margin-bottom: 10px;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-right: 115px;
  }
  .news-details-area .widget-area .widget:first-child {
    margin-top: 35px;
  }
  .faq-contact-area .section-title {
    margin-bottom: 30px;
  }
  .single-offer h3 {
    font-size: 20px;
  }
  .client-img h3 {
    font-size: 18px;
  }
  .modal-video-body {
    padding: 0 30px;
  }
  .main-banner-area-three {
    padding-bottom: 100px;
  }
  .main-banner-area-three .banner-main-img {
    margin-top: 50px;
  }
  .jumpx-slider-item {
    height: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .service-sidebar-area {
    margin-top: 30px;
  }
  .single-widget .logo img {
    max-width: 130px;
  }
  .main-contact-area .contact-wrap .contact-form {
    padding: 40px;
  }
  .main-contact-area .contact-wrap .contact-form .section-title {
    margin-bottom: 30px;
  }
  .navbar-area nav .navbar-nav .nav-item:nth-last-child(1) .dropdown-menu, .navbar-area nav .navbar-nav .nav-item:nth-last-child(2) .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area nav .navbar-nav .nav-item a {
    margin: 0 8px;
  }
  .navbar-area .others-option .default-btn {
    padding: 10px 20px;
  }
  .main-banner-area .container-fluid {
    max-width: 960px;
  }
  .main-banner-area {
    padding-top: 150px;
    padding-bottom: 250px;
  }
  .main-banner-area.main-banner-area-one .banner-img {
    top: -115px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(2) {
    top: -15px;
    width: 300px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(3) {
    top: 0;
    left: 45px;
    width: 200px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(4) {
    top: 80px;
    right: 0;
    max-width: 150px;
  }
  .features-area .container {
    max-width: 930px;
  }
  .features-area .single-features {
    padding: 20px;
  }
  .features-area .single-features p {
    margin-bottom: 20px;
  }
  .single-counter {
    padding: 30px;
  }
  .single-counter p {
    font-size: 18px;
  }
  .industries-area .industries-list-tab .tabs li h3 {
    font-size: 18px;
  }
  .industries-area .industries-list-tab .tabs li p {
    font-size: 13px;
  }
  .industries-area .industries-list-tab .tabs li span {
    font-size: 13px;
    padding-top: 20px;
    padding-left: 65px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .industries-area .industries-list-tab .tabs li a {
    padding-top: 20px;
    padding-left: 70px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .industries-area .industries-list-tab .tabs li i {
    left: 22px;
    top: 30px;
    font-size: 30px;
  }
  .main-banner-area-two {
    padding-top: 170px;
    padding-bottom: 200px;
  }
  .main-banner-area-two .banner-img img:nth-child(1) {
    width: 295px;
  }
  .main-banner-area-two .banner-img img:nth-child(2) {
    width: 250px;
  }
  .main-banner-area-two .banner-img img:nth-child(3) {
    top: 80px;
  }
  .main-banner-area-two .banner-img img:nth-child(5) {
    top: -50px;
  }
  .main-banner-area-two .banner-img img:nth-child(6) {
    top: 160px;
    left: -50px;
    width: 200px;
  }
  .main-banner-area-two .banner-img img:nth-child(7) {
    top: 120px;
    right: -45px;
    width: 200px;
  }
  .main-banner-area-two .banner-img img:nth-child(8) {
    top: 309px;
    right: -75px;
  }
  .main-banner-area-two .banner-img img:nth-child(9) {
    top: -130px;
    right: -225px;
  }
  .main-banner-area-two .banner-img img:nth-child(10) {
    top: 265px;
    left: 70px;
  }
  .main-banner-area-two .banner-img .square-img {
    top: -35px;
    width: 400px;
    margin: auto;
  }
  .main-banner-area-two .banner-img .circle-img {
    top: -210px;
    position: relative;
  }
  .main-banner-area-three .banner-img img:nth-child(2) {
    top: 80px;
    width: 150px;
  }
  .main-banner-area-four {
    padding-top: 200px;
    padding-bottom: 180px;
  }
  .main-banner-area-four .banner-text {
    top: 0;
  }
  .contact-info-area .single-contact-info {
    padding: 15px;
  }
  .main-contact-area .contact-wrap .contact-form {
    padding: 20px;
  }
  #map {
    height: 388px;
  }
  .footer-bottom-area .copy-right p {
    font-size: 13px;
  }
  .footer-bottom-area .privacy ul li a {
    font-size: 13px;
  }
  .footer-bottom-area .designed p {
    font-size: 13px;
  }
  .go-top.active {
    top: 92%;
  }
  .main-banner-area .banner-text h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .main-banner-area .banner-text p {
    font-size: 15px;
  }
  .about-content h2 {
    font-size: 30px;
  }
  .choose-title h2 {
    font-size: 30px;
  }
  .business-content h2 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .section-title h2 {
    font-size: 35px;
  }
  .single-features h3 {
    font-size: 20px;
  }
  .single-offer h3 {
    font-size: 20px;
  }
  .call-us h2 {
    font-size: 28px;
  }
  .call-us span {
    font-size: 30px;
  }
  .newsletter-wrap h2 {
    font-size: 28px;
  }
  .single-counter h2 span {
    font-size: 40px;
  }
  .client-img h3 {
    font-size: 18px;
  }
  .single-industries {
    padding-top: 25px;
    padding-left: 80px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
  .single-industries h3 {
    font-size: 18px;
  }
  .single-industries i {
    left: 25px;
  }
  .service-sidebar-area .service-details-title {
    font-size: 20px;
  }
  .single-news .news-content-wrap a h3 {
    font-size: 20px;
  }
  .contact-info-area .single-contact-info p {
    font-size: 14px;
  }
  .main-contact-area .contact-wrap .contact-form {
    padding: 40px;
  }
  .main-contact-area .contact-wrap .contact-form .section-title {
    margin-bottom: 30px;
  }
  .single-widget h3 {
    font-size: 22px;
  }
  .single-widget .logo img {
    max-width: 140px;
  }
  .single-offer {
    padding-right: 20px;
    padding-left: 75px;
  }
  .single-offer i {
    left: 20px;
  }
}
@media only screen and (min-width: 1824px) {
  .main-banner-area.main-banner-area-one {
    padding-top: 250px;
    padding-bottom: 350px;
  }
  .main-banner-area.main-banner-area-one .banner-text {
    top: 40px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(1) {
    bottom: -445px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(2) {
    bottom: -850px;
    left: 130px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(3) {
    left: 210px;
  }
  .main-banner-area.main-banner-area-one .banner-img img:nth-child(4) {
    right: 170px;
  }
  .main-banner-area-two {
    height: 100vh;
  }
  .main-banner-area-two .banner-text {
    top: 110px;
  }
  .main-banner-area-two .banner-img {
    top: 90px;
  }
  .main-banner-area-two .banner-img img:nth-child(6) {
    top: 15px;
    left: -25px;
  }
  .main-banner-area-two .banner-img img:nth-child(7) {
    right: 60px;
  }
  .main-banner-area-two .banner-img img:nth-child(8) {
    top: 370px;
    right: 75px;
  }
  .main-banner-area-two .banner-img img:nth-child(9) {
    right: 0;
  }
  .main-banner-area-two .banner-img img:nth-child(10) {
    top: 195px;
    left: 185px;
  }
  .footer-top-area .footer-shape img:nth-child(1) {
    right: 10%;
  }
  .footer-top-area .footer-shape img:nth-child(2) {
    right: 10%;
  }
  .main-banner-area-three {
    padding-top: 300px;
    overflow: hidden;
    height: 100vh !important;
  }
}
@media only screen and (max-width: 991px) {
  .mobile-nav .logo {
    top: 12px;
  }
  .mean-container .mean-bar {
    background-color: #04063c;
    box-shadow: 0 0 15px #ffffff;
    padding: 0;
    height: 65px;
  }
  .mobile-nav.mean-container .mean-nav ul li a:hover {
    color: #00a7f5;
  }
  .mobile-nav.mean-container .mean-nav ul li a.active {
    color: #00a7f5;
  }
  .mean-container a.meanmenu-reveal {
    padding: 22px 15px 0 0;
  }
  .mean-container .mean-nav {
    margin-top: 66px;
  }
}/*# sourceMappingURL=responsive.css.map */