
@font-face {
  font-family: "Flaticon";
  src: url("./fonts/Flaticon.eot");
  src: url("./fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./fonts/Flaticon.woff2") format("woff2"),
       url("./fonts/Flaticon.woff") format("woff"),
       url("./fonts/Flaticon.ttf") format("truetype"),
       url("./fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-cloud-computing:before { content: "\f100"; }
.flaticon-cloud-computing-1:before { content: "\f101"; }
.flaticon-expand:before { content: "\f102"; }
.flaticon-tick:before { content: "\f103"; }
.flaticon-quote:before { content: "\f104"; }
.flaticon-right-arrow:before { content: "\f105"; }
.flaticon-right-chevron:before { content: "\f106"; }
.flaticon-left-arrow:before { content: "\f107"; }
.flaticon-left-chevron:before { content: "\f108"; }
.flaticon-engineer:before { content: "\f109"; }
.flaticon-success:before { content: "\f10a"; }
.flaticon-chip:before { content: "\f10b"; }
.flaticon-vr:before { content: "\f10c"; }
.flaticon-blockchain:before { content: "\f10d"; }
.flaticon-target:before { content: "\f10e"; }
.flaticon-choice:before { content: "\f10f"; }
.flaticon-deep-learning:before { content: "\f110"; }
.flaticon-promotion:before { content: "\f111"; }
.flaticon-cyber-security:before { content: "\f112"; }
.flaticon-machine-learning:before { content: "\f113"; }
.flaticon-artificial-intelligence:before { content: "\f114"; }
.flaticon-health:before { content: "\f115"; }
.flaticon-automation:before { content: "\f116"; }
.flaticon-left-quotes-sign:before { content: "\f117"; }
.flaticon-user:before { content: "\f118"; }
.flaticon-calendar:before { content: "\f119"; }
.flaticon-conversation:before { content: "\f11a"; }
.flaticon-call:before { content: "\f11b"; }
.flaticon-envelope:before { content: "\f11c"; }
.flaticon-maps-and-flags:before { content: "\f11d"; }
.flaticon-checked:before { content: "\f11e"; }
.flaticon-checked-1:before { content: "\f11f"; }
.flaticon-technical-support:before { content: "\f120"; }
.flaticon-shield:before { content: "\f121"; }
.flaticon-support:before { content: "\f122"; }
.flaticon-pie-chart:before { content: "\f123"; }
.flaticon-pie-chart-1:before { content: "\f124"; }
.flaticon-standard:before { content: "\f125"; }
.flaticon-diamond:before { content: "\f126"; }
.flaticon-interactivity:before { content: "\f127"; }
.flaticon-lock:before { content: "\f128"; }
.flaticon-project-management:before { content: "\f129"; }
.flaticon-offer:before { content: "\f12a"; }