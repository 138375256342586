/*
 @File: Jumpx Template Styles
 * This file contains the styling for the actual template, this
 is the file you need to edit to change the look of the
 template.
 This files table contents are outlined below>>>>>
 ******************************************* 
 ** - Default Area Style
 ** - Preloader Area Style
 ** - Go Top Area Style
 ** - Page navigation Area Style  
 ** - Video wave Style
 ** - Default Btn Area Style
 ** - Read More Area Style
 ** - Section Title Area Style 
 ** - Preloader Area Style
 ** - Keyframes Area Style 
 ** - Nice Select Area Style
 ** --- HOME ONE STYLE --- **
 ** - Milford Nav Area Style
 ** - Main Banner Area Style
 ** - Features Area Style
 ** - About Area Style 
 ** - Choose Us Area Style
 ** - Offer Area Style
 ** - Business  Area Style
 ** - Pricing Area Style
 ** - Industries  Area Style
 ** - Client Area Style
 ** - News Area Style
 ** - Footer Top Area Style
 ** - Footer Bottom Area Style
 ** --- HOME TWO STYLE --- **
 ** - Banner Two Area Style
 ** - Offer Area Two Style
 ** - Pricing Area Style
 ** - Newsletter Area Style
 ** - Industries Serve Area Style
 ** - Brand Area Serve Area Style 
 ** --- HOME THREE STYLE --- **
 ** - Main Banner Area Three Style
 ** - Team Area Three Style
 ** - Contact Area Three Style
 ** --- HOME FOUR STYLE --- **
 ** - Main Banner Four Area Style
 ** - Case Area Style
 ** - FAQ CSS
 ** --- HOME FOUR STYLE --- **
 ** - Banner Four Area Style
 ** - Bg Color Four Area Style
 ** - Home Four Section Title Color Area Style
 ** - About Area Four Style
 ** - Business Area Four Style
 ** - Offer Area Four Style
 ** - Industries Area Four Style
 ** - Team Area Four Style
 ** --- HOME FIVE STYLE --- **
 ** - SliderArea Style
 ** --- INNER PAGE STYLE --- **
 ** - Page Title Area Style
 ** - 404 Error Area Style
 ** - Coming Soon Area Style
 ** - Sign In & Log In  Area Style
 ** - Ask Question Area Style
 ** - Checkout Area Style 
 ** - Cart Area Style
 ** - Blog Details Area Style
 ** - Contact Info Area Style
 ** - Main Contact Area Style
 ** - Contact Info Area Three Style 
 ** - Service Details Area Style
 *******************************************
 /*
 /*
 Default Style
 ============================*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&subset=latin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&subset=latin&display=swap');
body, html {
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 1.8;
  font-family: "Open Sans", sans-serif;
  color: #4d4d4d;
}
a {
  display: inline-block;
  transition: all 0.6s ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #212121;
}
a:focus {
  text-decoration: none;
}
button {
  outline: 0 !important;
  box-shadow: none;
  border: none;
  background-color: transparent;
}
button:focus {
  box-shadow: none;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #212121;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
}
h3 {
  font-size: 22px;
}
.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}
p {
  font-size: 15px;
  margin-bottom: 15px;
  line-height: 1.8;
}
p:last-child {
  margin-bottom: 0;
}
img {
  max-width: 100%;
  height: auto;
}
.bg-fafafa {
  background-color: #f5f5f5;
}
.form-control {
  height: 50px;
  color: #212121;
  border: 1px solid #e8e8e8;
  background-color: #f7f7f7;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;
}
.form-control::placeholder {
  color: #495057;
}
.form-control:focus {
  color: #000000;
  background-color: transparent;
  box-shadow: unset;
  outline: 0;
  border: 1px solid #1dd3f8;
}
.form-control:hover:focus, .form-control:focus {
  box-shadow: unset;
}
textarea.form-control {
  height: auto;
}
.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.mb-30 {
  margin-bottom: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-100 {
  margin-top: 100px;
}
.mtb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mt-minus-70 {
  margin-top: -70px;
}
.mb-minus-70 {
  margin-bottom: -70px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.fun-blue-bg {
  background-color: #24416b;
}
/*
 Bg Color Four Area Style
 ======================================================*/
.body-bg-color {
  background-color: #010032;
}
/*
 Go Top Style
 ======================================================*/
.scroll-to-top {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
  background-color: #212121;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  transition: 0.9s;
}
.scroll-to-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  transition: 0.5s;
  font-size: 20px;
}
.scroll-to-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.scroll-to-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #1dd3f8;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.scroll-to-top:hover {
  color: #ffffff;
  background-color: #1dd3f8;
}
.scroll-to-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.scroll-to-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.scroll-to-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.scroll-to-top:focus {
  color: #ffffff;
}
.scroll-to-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.scroll-to-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.scroll-to-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
/*
 Page-navigation Area Style
 ======================================================*/
.page-navigation-area {
  text-align: center;
  margin-top: 30px;
}
.page-navigation-area .pagination {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}
.page-navigation-area .pagination li {
  display: inline-block;
}
.page-navigation-area .page-link {
  color: #1dd3f8;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  line-height: 40px;
  transition: all 0.6s ease;
  font-weight: 700;
  border-radius: 10px !important;
  padding: 0;
}
.page-navigation-area .page-link i {
  margin-right: -4px;
}
.page-navigation-area .page-link:hover {
  color: #ffffff;
  background-color: #1dd3f8;
  border: 1px solid #1dd3f8;
  animation: rubberBand 1s linear;
}
.page-navigation-area .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}
.page-navigation-area .page-link.page-links i::before {
  margin-left: -4px;
}
.page-navigation-area .page-item {
  padding: 0 8px 0;
}
.page-navigation-area .page-item:first-child .page-link {
  border-radius: none;
}
.page-navigation-area .page-item:last-child .page-link {
  border-radius: none;
}
.page-navigation-area .page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #1dd3f8;
  border-color: #1dd3f8;
}
/*
 Video wave Style
 ======================================================*/
.video-btn {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 69px;
  text-align: center;
  border-radius: 50%;
  color: #1dd3f8;
  position: relative;
  top: 3px;
  z-index: 1;
  background-color: #ffffff;
}
.video-btn i {
  font-size: 30px;
  font-weight: 700;
  padding-left: 4px;
  color: #1dd3f8;
  position: relative;
  top: 5px;
  left: 0;
}
.video-btn::after, .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: #ffffff;
}
.video-btn::before {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn::after {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn:hover {
  background-color: #1dd3f8;
}
.video-btn:hover i {
  color: #ffffff;
}
@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
/*
 Default Btn Area Style
 ======================================================*/
.default-btn {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  padding: 18px 35px;
  line-height: 1;
  transition: all 0.6s ease;
  z-index: 1;
  text-transform: capitalize;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: #1dd3f8;
  display: inline-block;
}
.default-btn::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -40px;
  width: 10px;
  height: 100px;
  background-color: #ffffff;
  opacity: 0.5;
  transition: all 0.6s ease;
  transform: rotate(45deg);
}
.default-btn::after {
  content: "";
  position: absolute;
  top: -20px;
  right: -40px;
  width: 10px;
  height: 100px;
  background-color: #ffffff;
  opacity: 0.5;
  transition: all 0.6s ease;
  transform: rotate(45deg);
}
.default-btn.active {
  background-color: #ffffff;
  color: #1dd3f8;
}
.default-btn.active:hover {
  color: #ffffff;
  background-color: #1dd3f8;
}
.default-btn:hover {
  color: #ffffff;
  transition: all 0.6s ease;
}
.default-btn:hover::before {
  left: 200px;
}
.default-btn:hover::after {
  right: 200px;
}
.popup-youtube {
  cursor: pointer;
}
/*
 Read More Area Style
 ======================================================*/
.read-more {
  color: #4d4d4d;
  font-weight: 600;
  transition: all 0.6s ease;
  display: block;
  font-size: 14px;
  border: 1px solid #4d4d4d;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
}
.read-more i {
  position: relative;
  top: 3px;
  font-size: 18px;
  left: 3px;
  line-height: 19px;
  border-radius: 50%;
  transition: all 0.6s ease;
}
.read-more:hover {
  background-color: #1dd3f8;
  color: #ffffff;
  border-color: #1dd3f8;
}
/*
 Section Title Area Style
 ======================================================*/
.section-title {
  max-width: 625px;
  margin: -9px auto 60px;
  text-align: center;
  position: relative;
}
.section-title span {
  font-size: 14px;
  color: #1dd3f8;
  display: inline-block;
  margin-bottom: 5px;
}
.section-title h2 {
  font-size: 40px;
  margin-bottom: 10px;
  position: relative;
  display: block;
}
.section-title h2:last-child {
  margin-bottom: 0;
}
/*
 Preloader Area Style
 ======================================================*/
.preloader-deactivate {
  display: none;
}
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #04063c;
  top: 0;
  left: 0;
  z-index: 99999;
}
.spinner {
  height: 40px;
  font-size: 10px;
  text-align: center;
}
.spinner > div {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
/*
 Keyframes Area Style
 ======================================================*/
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes movebounceShort {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moVebounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  80% {
    transform: translate(-300px, 0px) rotate(360deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes focus-one {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}
/*
 Nice Select Area Style
 ======================================================*/
.nice-select .option {
  display: block;
}
.nice-select .option:hover {
  background-color: #1dd3f8;
  color: #ffffff;
}
.nice-select .option.selected.focus {
  background-color: #212121;
  color: #1dd3f8;
}
.nice-select .list {
  width: 100%;
}
/*===== DEFAULT STYLE END ======*/
/*
 jumpx Nav Area Style
 ======================================================*/
.navbar-area nav {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.6s ease;
}
.navbar-area nav .navbar-brand .logo {
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
}
.navbar-area nav .navbar-nav .nav-item {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
}
.navbar-area nav .navbar-nav .nav-item a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.navbar-area nav .navbar-nav .nav-item a:hover, .navbar-area nav .navbar-nav .nav-item a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item a i {
  font-size: 22px;
  position: relative;
  top: 3px;
}
.navbar-area nav .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.navbar-area nav .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.navbar-area nav .navbar-nav .nav-item:hover a, .navbar-area nav .navbar-nav .nav-item.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  background: #ffffff;
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 250px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  padding-top: 18px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 18px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a {
  padding: 5px 15px;
  margin: 0;
  color: #000000;
  text-transform: capitalize;
  font-size: 14.5px;
  font-weight: 400;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
  font-size: 14px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 220px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: #000000;
  text-transform: capitalize;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li.active a {
  color: #1dd3f8;
}
.navbar-area nav .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area nav .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.navbar-area .dropdown-toggle::after {
  display: none;
}
.navbar-area .others-options {
  position: relative;
  top: 3px;
}
.navbar-area .others-options .default-btn {
  padding: 15px 35px;
}
.navbar-area .others-options .default-btn i {
  position: relative;
  top: 1px;
  margin-left: 5px;
}
.navbar-area.is-sticky nav {
  background-color: #000000;
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar-toggler {
  border: none;
  background: #eee !important;
  padding: 10px;
  border-radius: 0;
}
.navbar-toggler .icon-bar {
  width: 28px;
  transition: all 0.3s;
  background: #1dd3f8;
  height: 2px;
  display: block;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 6px 0;
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
  left: 0;
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
  left: 0;
}
/*
 Main Banner Area Style
 ======================================================*/
.main-banner-area {
  background-image: linear-gradient(#00095e, #000638);
  padding-top: 200px;
  padding-bottom: 300px;
  position: relative;
  z-index: 1;
}
.main-banner-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./images/home-one/shape/animate4.png);
  animation: focus-one 1s linear 1s infinite;
  z-index: -1;
}
.main-banner-area::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./images/home-one/shape/animate4.png);
  animation: moVebounce 5s linear infinite;
  z-index: -1;
}
.main-banner-area .banner-text {
  position: relative;
  max-width: 640px;
  margin-left: auto;
  z-index: 5;
}
.main-banner-area .banner-text h1 {
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 32px;
}
.main-banner-area .banner-text p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 40px;
}
.main-banner-area .banner-text .banner-btn .default-btn {
  margin-right: 20px;
}
.main-banner-area .banner-text .banner-btn .default-btn:last-child {
  margin-right: 0;
}
.main-banner-area .banner-main-img {
  display: none;
}
.main-banner-area .white-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.main-banner-area .white-shape img {
  width: 100%;
}
.main-banner-area.main-banner-area-one {
  overflow: hidden;
  z-index: 1;
}
.main-banner-area.main-banner-area-one .banner-text {
  top: 60px;
}
.main-banner-area.main-banner-area-one .banner-img {
  position: relative;
  z-index: 1;
  top: 0;
}
.main-banner-area.main-banner-area-one .banner-img img {
  position: absolute;
}
.main-banner-area.main-banner-area-one .banner-img img:nth-child(1) {
  bottom: -440px;
  right: 0;
  left: 0;
  margin: auto;
}
.main-banner-area.main-banner-area-one .banner-img img:nth-child(2) {
  top: -170px;
  left: 0;
}
.main-banner-area.main-banner-area-one .banner-img img:nth-child(3) {
  top: -100px;
  left: 70px;
}
.main-banner-area.main-banner-area-one .banner-img img:nth-child(4) {
  top: -33px;
  right: 65px;
}
.main-banner-area.main-banner-area-one .over-shape img {
  position: absolute;
  z-index: -1;
}
.main-banner-area.main-banner-area-one .over-shape img:nth-child(1) {
  top: 300px;
  left: 40px;
  animation: animationFramesOne 25s infinite linear;
}
.main-banner-area.main-banner-area-one .over-shape img:nth-child(2) {
  top: 200px;
  left: 800px;
  animation: animationFramesOne 40s infinite linear;
}
.main-banner-area.main-banner-area-one .over-shape img:nth-child(3) {
  top: 200px;
  right: 200px;
  animation: animationFramesOne 25s infinite linear;
}
/*
 Features Area Style
 ======================================================*/
.single-features {
  text-align: center;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.6s ease;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.single-features i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  position: relative;
  border: 2px dotted #1dd3f8;
  border-radius: 50%;
  font-size: 30px;
  color: #1dd3f8;
  animation: border-transform 7s linear infinite alternate forwards;
  transition: all 0.6s ease;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  color: #1dd3f8;
}
.single-features h3 {
  margin-bottom: 12px;
}
.single-features h4 {
  margin-left: 10px;
}
.single-features p {
  margin-left: 10px;
  margin-right: 25px;
  margin-bottom: 0px;
  text-align: justify;
  vertical-align: center;
  color: #212121;
  font-family: Dosis, sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}
.single-features .read-more-icon {
  color: #212121;
  position: absolute;
  right: -15px;
  width: 40px;
  margin: auto;
  top: calc(50% - 20px);
}
.single-features .read-more-icon span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: inline-block;
  font-weight: 600;
  transition: all 0.6s ease;
}
.single-features:hover {
  transform: scale(1.05);
  z-index: 1;
}
.single-features:hover i {
  background-color: #1dd3f8;
  color: #ffffff;
}
.single-features:hover .read-more-icon span {
  background-color: #1dd3f8;
  color: #ffffff;
}
.col-lg-4:nth-child(1) .single-features {
  border-radius: 4px 0 0 4px;
  position: relative;
}
.col-lg-4:nth-child(1) .single-features::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-position: left;
  background-repeat: no-repeat;
}
.col-lg-4:nth-child(2) .single-features {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}
.col-lg-4:nth-child(3) .single-features {
  border-radius: 0 4px 4px 0;
  position: relative;
}
.col-lg-4:nth-child(3) .single-features::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: right;
  background-repeat: no-repeat;
}
/*
 About Area Style
 ======================================================*/
.about-img {
  text-align: center;
}
.about-content {
  margin-top: -8px;
}
.about-content span {
  font-size: 14px;
  color: #1dd3f8;
  display: inline-block;
  margin-bottom: 8px;
}
.about-content h2 {
  font-size: 40px;
  margin-bottom: 15px;
}
.about-content ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0;
}
.about-content ul li {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
}
.about-content ul li:last-child {
  margin-bottom: 0;
}
.about-content ul li i {
  position: absolute;
  top: 1px;
  left: 0;
  color: #1dd3f8;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.about-content .default-btn {
  margin-top: 30px;
}
/*
 Choose Us Area Style
 ======================================================*/
.features-area {
  position: relative;
  z-index: 1;
}
.choose-title {
  margin-top: -9px;
  margin-bottom: 30px;
}
.choose-title span {
  font-size: 14px;
  color: #1dd3f8;
  display: inline-block;
  margin-bottom: 8px;
}
.choose-title h2 {
  font-size: 40px;
  margin-bottom: 0;
}
.choose-card {
  position: relative;
  padding-left: 120px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 25px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  transition: all 0.6s ease;
  cursor: pointer;
}
.choose-card span {
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
  border: 2px dotted #ffffff;
  border-radius: 50%;
  font-size: 25px;
  font-weight: 600;
  color: #212121;
  animation: border-transform 7s linear infinite alternate forwards;
  transition: all 0.6s ease;
  background-color: #dcddfa;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  display: inline-block;
  position: absolute;
  left: 30px;
}
.choose-card span i {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: inline-block;
  font-size: 20px;
  color: #dcddfa;
}
.choose-card h3 {
  margin-bottom: 10px;
}
.choose-card:nth-child(even) span {
  background-color: #c8fbfa;
}
.choose-card:nth-child(even) span i {
  color: #c8fbfa;
}
.choose-card:nth-child(even) span {
  background-color: #bde5fa;
}
.choose-card:nth-child(even) span i {
  color: #bde5fa;
}
.choose-card:hover {
  transform: translateY(-5px);
}
/*
 Offer Area Style
 ======================================================*/
.offer-area {
  background-color: #f2fefe;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.offer-area.gray-bg {
  background-color: #f0f0f0;
}
.offer-area .offer-shape img {
  position: absolute;
  z-index: -1;
}
.offer-area .offer-shape img:nth-child(1) {
  bottom: 0;
  left: 0;
}
.offer-area .offer-shape img:nth-child(2) {
  top: 0;
  right: 0;
}
.offer-area .offer-shape img:nth-child(3) {
  top: 100px;
  left: 200px;
  animation: animationFramesOne 25s infinite linear;
}
.offer-area .offer-shape img:nth-child(4) {
  bottom: 80px;
  right: 200px;
  animation: animationFramesOne 20s infinite linear;
}
.offer-area .offer-shape img:nth-child(5) {
  top: 100px;
  right: 200px;
  animation: animationFramesOne 15s infinite linear;
}
.offer-area .offer-shape img:nth-child(6) {
  bottom: 80px;
  left: 500px;
  animation: animationFramesOne 10s infinite linear;
}
.offer-area .offer-shape img:nth-child(7) {
  top: 100px;
  left: 700px;
  animation: animationFramesOne 10s infinite linear;
}
.single-offer {
  position: relative;
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 110px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px dotted #1dd3f8;
  transition: all 0.6s ease;
  margin-bottom: 30px;
}
.single-offer i {
  position: absolute;
  left: 30px;
  top: 28px;
  background-color: #eeeefe;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 4px;
  font-size: 40px;
  color: #9898f0;
}
.single-offer h3 {
  margin-bottom: 10px;
}
.single-offer h3 a {
  color: #212121;
}
.single-offer h3 a:hover {
  color: #1dd3f8;
}
.single-offer:hover {
  transform: translateY(-5px);
}
.col-lg-4:nth-child(2) .single-offer {
  border-color: #76b8f5;
}
.col-lg-4:nth-child(2) .single-offer i {
  background-color: #e8f3fd;
  color: #76b8f5;
}
.col-lg-4:nth-child(3) .single-offer {
  border-color: #1ccdca;
}
.col-lg-4:nth-child(3) .single-offer i {
  background-color: #e6fdfc;
  color: #1ccdca;
}
.col-lg-4:nth-child(4) .single-offer {
  border-color: #f9ca54;
}
.col-lg-4:nth-child(4) .single-offer i {
  background-color: #fcf3dc;
  color: #f9ca54;
}
.col-lg-4:nth-child(5) .single-offer {
  border-color: #ff896b;
}
.col-lg-4:nth-child(5) .single-offer i {
  background-color: #fde2db;
  color: #ff896b;
}
.col-lg-4:nth-child(6) .single-offer {
  border-color: #50d890;
}
.col-lg-4:nth-child(6) .single-offer i {
  background-color: #e7fdf1;
  color: #50d890;
}
.service-card {
  background-color: #ffffff;
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: all 0.6s ease;
}
.service-card i {
  background-color: #e8f3fd;
  color: #76b8f5;
  display: inline-block;
  height: 75px;
  width: 75px;
  text-align: center;
  line-height: 75px;
  font-size: 40px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.service-card h3 a {
  color: #212121;
}
.service-card h3 a:hover {
  color: #1dd3f8;
}
.service-card p {
  margin: 0;
}
.service-card:hover {
  transform: translateY(-6px);
}
/*
 Business  Area Style
 ======================================================*/
.business-area .default-btn {
  margin-top: 10px;
}
.business-content h2 {
  font-size: 40px;
  margin-top: -9px;
  margin-bottom: 40px;
}
.single-business {
  padding-left: 70px;
  transition: all 0.6s ease;
  margin-bottom: 30px;
  position: relative;
}
.single-business i {
  position: absolute;
  left: 0;
  top: 5px;
  background-color: #eeeefe;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 0px 30px 30px 30px;
  font-size: 25px;
  color: #9898f0;
}
.single-business h3 {
  margin-bottom: 10px;
}
.single-business:hover {
  transform: translateY(-5px);
}
.single-counter {
  background-color: #e2e2fc;
  text-align: center;
  padding: 50px;
  border-radius: 4px;
  margin-bottom: 30px;
  transition: all 0.6s ease;
}
.single-counter h2 {
  margin-bottom: 0;
}
.single-counter h2 span {
  font-size: 50px;
  color: #6b6bd4;
  font-family: "Dosis", sans-serif;
  font-weight: 700;
  line-height: 1;
}
.single-counter h2 .target {
  line-height: 1;
  position: relative;
  top: 7px;
}
.single-counter p {
  font-family: "Dosis", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 0;
}
.single-counter:hover {
  animation: rubberBand 1s linear;
}
.counter-nth:nth-child(2) .single-counter {
  margin-top: 30px;
  background-color: #cae5fe;
}
.counter-nth:nth-child(2) .single-counter h2 span {
  color: #3785ca;
}
.counter-nth:nth-child(3) .single-counter {
  background-color: #c0fedc;
  margin-top: -30px;
  margin-bottom: 0;
}
.counter-nth:nth-child(3) .single-counter h2 span {
  color: #23bc68;
}
.counter-nth:nth-child(4) .single-counter {
  background-color: #fbc7ba;
  margin-bottom: 0;
}
.counter-nth:nth-child(4) .single-counter h2 span {
  color: #c05234;
}
/*
 Industries Area Style
 ======================================================*/
.industries-area .industries-content h3 {
  margin-bottom: 15px;
}
.industries-area .industries-content p {
  margin-bottom: 10px;
}
.industries-area .industries-content .default-btn {
  margin-top: 15px;
  display: inline-block;
}
.industries-area .industries-content .industries-item {
  list-style-type: none;
  margin: 15px 0 0;
  padding: 0;
}
.industries-area .industries-content .industries-item li {
  font-size: 15px;
  font-weight: 600;
  color: #212121;
  font-family: "Dosis", sans-serif;
  margin-bottom: 15px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: relative;
  padding-left: 40px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
}
.industries-area .industries-content .industries-item li i {
  position: absolute;
  top: 13px;
  left: 17px;
  color: #1dd3f8;
}
.industries-area .industries-list-tab .tabs {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.industries-area .industries-list-tab .tabs li {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 60px 0 0 60px;
  transition: all 0.6s ease;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 20px;
}
.industries-area .industries-list-tab .tabs li:last-child {
  margin-bottom: 0;
}
.industries-area .industries-list-tab .tabs li span {
  display: inline-block;
  cursor: pointer;
  padding-top: 30px;
  padding-left: 85px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.industries-area .industries-list-tab .tabs li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #1dd3f8;
  z-index: -1;
  border-radius: 60px 0 0 60px;
  transition: all 0.6s ease;
}
.industries-area .industries-list-tab .tabs li i {
  position: absolute;
  left: 30px;
  top: 32px;
  font-size: 40px;
  color: #1dd3f8;
  line-height: 1;
  transition: all 0.6s ease;
}
.industries-area .industries-list-tab .tabs li h3 {
  transition: all 0.6s ease;
  font-size: 20px;
}
.industries-area .industries-list-tab .tabs li p {
  margin-bottom: 0;
  display: block;
  transition: all 0.6s ease;
  color: #4d4d4d;
}
.industries-area .industries-list-tab .tabs li:hover i {
  color: #ffffff;
  transform: rotate(360deg);
}
.industries-area .industries-list-tab .tabs li:hover h3 {
  color: #ffffff;
}
.industries-area .industries-list-tab .tabs li:hover p {
  color: #ffffff;
}
.industries-area .industries-list-tab .tabs li:hover a {
  right: 20px;
}
.industries-area .industries-list-tab .tabs li:hover::before {
  width: 100%;
  border-radius: 60px 0 0 60px;
}
.industries-area .industries-list-tab .tabs li.current i {
  color: #ffffff;
  transform: rotate(360deg);
}
.industries-area .industries-list-tab .tabs li.current h3 {
  color: #ffffff;
}
.industries-area .industries-list-tab .tabs li.current p {
  color: #ffffff;
}
.industries-area .industries-list-tab .tabs li.current a {
  right: 20px;
}
.industries-area .industries-list-tab .tabs li.current::before {
  width: 100%;
  border-radius: 60px 0 0 60px;
}
.industries-area .industries-img {
  text-align: center;
}
.tab .tabs_item {
  display: none;
}
.tab .tabs_item:first-child {
  display: block;
}
/*
 Client Area Style
 ======================================================*/
.testimonials-slide .swiper-button-next, .testimonials-slide .swiper-button-prev {
  top: 60px;
}
.testimonials-slide .swiper-button-next::after, .testimonials-slide .swiper-button-prev::after {
  font-size: 25px;
  color: #1dd3f8;
}
.client-area {
  background-color: #192064;
}
.client-area .section-title h2 {
  color: #ffffff;
}
.single-client {
  text-align: center;
  background-color: #ffffff;
  position: relative;
  margin-top: 30px;
  margin-bottom: 165px;
  border-radius: 4px;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.single-client .quotes {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  color: #1dd3f8;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -30px;
}
.single-client ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.single-client ul li {
  display: inline-block;
  margin: 0 2px;
}
.single-client ul li i {
  font-size: 20px;
  color: orange;
}
.single-client::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 45px solid #ffffff;
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 50px solid transparent;
  bottom: -24px;
  left: 20px;
}
.client-img {
  position: absolute;
  left: 30px;
  bottom: -170px;
}
.client-img img {
  border-radius: 10px;
  margin-bottom: 15px;
}
.client-img h3 {
  margin-bottom: 0;
  font-size: 20px;
  color: #ffffff;
}
.client-img span {
  font-weight: 600;
  font-size: 13px;
  line-height: 1;
  color: #1dd3f8;
}
/*
 News Area Style
 ======================================================*/
.single-news {
  margin-bottom: 30px;
}
.single-news .blog-img {
  position: relative;
  overflow: hidden;
}
.single-news .blog-img .dates {
  position: absolute;
  top: 0;
  left: -200px;
  font-size: 15px;
  background-color: #1dd3f8;
  padding: 5px 10px;
  color: #ffffff;
  transition: all 0.6s ease;
}
.single-news .news-content-wrap {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.single-news .news-content-wrap ul {
  margin-bottom: 10px;
  padding: 0;
  list-style-type: none;
}
.single-news .news-content-wrap ul li {
  display: inline-block;
  padding-right: 15px;
}
.single-news .news-content-wrap ul li:last-child {
  margin-right: 0;
}
.single-news .news-content-wrap ul li a {
  color: #4d4d4d;
}
.single-news .news-content-wrap ul li a:hover {
  color: #1dd3f8;
}
.single-news .news-content-wrap ul li i {
  color: #1dd3f8;
  margin-right: 5px;
  display: inline-block;
}
.single-news .news-content-wrap a h3 {
  margin-bottom: 10px;
  transition: all 0.6s ease;
  line-height: 1.3;
}
.single-news .news-content-wrap p {
  margin-bottom: 20px;
}
.single-news:hover .blog-img .dates {
  left: 0;
}
.single-news:hover .news-content-wrap a h3 {
  color: #1dd3f8;
}
.single-news:hover .news-content-wrap .read-more {
  background-color: #1dd3f8;
  color: #ffffff;
  border-color: #1dd3f8;
}
/*
 Footer Top Area Style
 ======================================================*/
.footer-top-area {
  background-color: #00042c;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.footer-top-area .footer-shape img {
  position: absolute;
}
.footer-top-area .footer-shape img:nth-child(1) {
  top: 35%;
  right: 0;
  z-index: -1;
  animation: rotation 20s linear infinite;
}
.footer-top-area .footer-shape img:nth-child(2) {
  top: 35%;
  right: 0;
  z-index: -1;
  animation: focus-one 1s linear 1s infinite;
}
.single-widget {
  margin-bottom: 30px;
}
.single-widget .logo {
  color: #ffffff;
  margin-bottom: 25px;
  font-size: 25px;
  font-weight: bold;
}
.single-widget p {
  color: #d9d6d6;
  margin-bottom: 20px;
}
.single-widget h3 {
  color: #ffffff;
  margin-bottom: 25px;
  font-size: 25px;
  position: relative;
  border-bottom: 2px solid #060c4a;
  padding-bottom: 10px;
}
.single-widget h3::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #ffffff;
}
.single-widget ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.single-widget ul li {
  margin-bottom: 10px;
}
.single-widget ul li:last-child {
  margin-bottom: 0;
}
.single-widget ul li a {
  color: #d9d6d6;
  position: relative;
  padding-left: 20px;
}
.single-widget ul li a .right-icon {
  font-size: 20px;
  position: absolute;
  top: 3px;
  left: 0;
}
.single-widget ul li a:hover {
  color: #1dd3f8;
  transform: translateX(5px);
}
.single-widget .information li {
  margin-bottom: 8px;
}
.single-widget .information li a {
  padding-left: 0;
  position: relative;
}
.single-widget .information li a:hover {
  transform: translateX(0);
}
.single-widget .information li.address {
  position: relative;
  padding-left: 35px;
  color: #d9d6d6;
}
.single-widget .information li.address i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 20px;
}
.single-widget .information li.address span {
  display: block;
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.single-widget .social-icon {
  line-height: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.single-widget .social-icon li {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 0;
}
.single-widget .social-icon li a {
  width: 30px;
  height: 30px;
  line-height: 32px;
  background-color: #3955bc;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  position: relative;
  padding-left: 0;
}
.single-widget .social-icon li a:hover {
  transform: translateX(0px);
  animation: rubberBand 1s linear;
}
.single-widget .social-icon li:nth-child(2) a {
  background-color: #1da1f2;
  color: #ffffff;
}
.single-widget .social-icon li:nth-child(3) a {
  background-color: #1da1f2;
  color: #ffffff;
}
.single-widget .social-icon li:nth-child(4) a {
  background-color: #d32f2f;
  color: #ffffff;
}
.single-widget .social-icon li:nth-child(4) a {
  background-color: #0077b5;
  color: #ffffff;
}
.single-widget .social-icon li:nth-child(5) a {
  background-color: #ff0000;
  color: #ffffff;
}
/*
 Footer Bottom Area Style
 ======================================================*/
.footer-bottom-area {
  background-color: #0d1240;
  padding: 20px 0;
}
.footer-bottom-area .copy-right {
  color: #d9d6d6;
}
.footer-bottom-area .copy-right i {
  position: relative;
  top: 1px;
  margin-right: -5px;
}
.footer-bottom-area .copy-right a {
  color: #d9d6d6;
}
.footer-bottom-area .copy-right a:hover {
  color: #1dd3f8;
}
.footer-bottom-area .designed {
  text-align: right;
  color: #d9d6d6;
}
.footer-bottom-area .designed i {
  position: relative;
  top: 2px;
  color: #1dd3f8;
  animation: heartBeat 1000ms infinite linear;
}
.footer-bottom-area .designed a {
  color: #1dd3f8;
}
.footer-bottom-area .privacy {
  text-align: center;
}
.footer-bottom-area .privacy ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.footer-bottom-area .privacy ul li {
  display: inline-block;
  padding-right: 20px;
}
.footer-bottom-area .privacy ul li:last-child {
  margin-right: 0;
}
.footer-bottom-area .privacy ul li a {
  color: #d9d6d6;
  position: relative;
}
.footer-bottom-area .privacy ul li a::before {
  content: "";
  position: absolute;
  top: 13px;
  left: -13px;
  width: 5px;
  height: 1px;
  background-color: #d9d6d6;
}
.footer-bottom-area .privacy ul li a:hover {
  color: #1dd3f8;
}
.footer-bottom-area .privacy ul li:first-child a::before {
  display: none;
}
/*====================================================
 HOME TWO STYLE
 ======================================================*/
/*
 Banner Two Area Style
 ======================================================*/
.main-banner-area-two {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 250px;
  overflow: hidden;
}
.main-banner-area-two .banner-text {
  top: 20px;
}
.main-banner-area-two .banner-img {
  position: relative;
  z-index: 1;
  top: 0;
}
.main-banner-area-two .banner-img .circle-img {
  top: -230px;
  position: relative;
}
.main-banner-area-two .banner-img .circle-img img {
  animation: rotation 20s linear infinite;
}
.main-banner-area-two .banner-img .square-img {
  position: relative;
  animation: movebounceShort 5s linear infinite;
  top: -70px;
}
.main-banner-area-two .banner-img img {
  position: absolute;
}
.main-banner-area-two .banner-img img:nth-child(1) {
  bottom: -440px;
  right: 0;
  left: 0;
  margin: auto;
}
.main-banner-area-two .banner-img img:nth-child(3) {
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.main-banner-area-two .banner-img img:nth-child(5) {
  top: -120px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  animation: focus-one 1s linear 1s infinite;
}
.main-banner-area-two .banner-img img:nth-child(6) {
  top: 10px;
  left: -135px;
}
.main-banner-area-two .banner-img img:nth-child(7) {
  top: -100px;
  right: -45px;
  width: 300px;
}
.main-banner-area-two .banner-img img:nth-child(8) {
  top: 280px;
  right: -30px;
}
.main-banner-area-two .banner-img img:nth-child(9) {
  top: -215px;
  right: -260px;
}
.main-banner-area-two .banner-img img:nth-child(10) {
  top: 170px;
  left: 90px;
}
.main-banner-area-two .over-shape img {
  position: absolute;
  z-index: -1;
}
.main-banner-area-two .over-shape img:nth-child(1) {
  top: 300px;
  left: 40px;
  animation: animationFramesOne 25s infinite linear;
}
.main-banner-area-two .over-shape img:nth-child(2) {
  top: 200px;
  left: 800px;
  animation: animationFramesOne 40s infinite linear;
}
.main-banner-area-two .over-shape img:nth-child(3) {
  top: 200px;
  right: 200px;
  animation: animationFramesOne 25s infinite linear;
}
/*
 Offer Area Two Style
 ======================================================*/
.offer-area-two .single-offer:hover {
  background-color: #1dd3f8;
}
.offer-area-two .single-offer:hover h3 {
  color: #ffffff;
}
.offer-area-two .single-offer:hover p {
  color: #ffffff;
}
/*
 Pricing Area Style
 ======================================================*/
.single-pricing {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 50px 0 50px 0;
  transition: all 0.6s ease;
}
.single-pricing .pricing-top-heading {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.single-pricing .pricing-top-heading h3 {
  font-size: 25px;
}
.single-pricing .pricing-top-heading p {
  font-weight: 600;
  font-size: 14px;
}
.single-pricing span {
  font-size: 30px;
  font-family: "Dosis", sans-serif;
  color: #212121;
  font-weight: 700;
  display: block;
  line-height: 1;
  margin: 0 auto 20px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
}
.single-pricing span sub {
  font-size: 14px;
  color: #4d4d4d;
}
.single-pricing ul {
  text-align: left;
  max-width: 300px;
  margin: 0 auto 25px;
  list-style-type: none;
  padding: 0;
}
.single-pricing ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
  font-weight: 600;
}
.single-pricing ul li:last-child {
  margin-bottom: 0;
}
.single-pricing ul li i {
  font-size: 17px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #daf6fd;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  color: #1dd3f8;
}
.single-pricing ul li .bx-x {
  color: red;
}
.single-pricing .default-btn {
  padding: 15px 35px;
}
.single-pricing:hover {
  transform: translateY(-5px);
}
.popular {
  position: absolute;
  top: -18px;
  right: -50px;
  z-index: 1;
  background-color: red;
  color: #fff;
  padding: 40px 40px 10px 35px;
  transform: rotate(45deg);
  font-size: 13px;
}
.tab.quote-list-tab {
  text-align: center;
}
.tab.quote-list-tab .tabs {
  border-radius: 10px;
  display: inline-block;
  border: 1px solid #1dd3f8;
  margin-bottom: 40px;
  padding: 0;
}
.tab.quote-list-tab .tabs li {
  display: inline-block;
  padding: 0 10px;
  font-family: "Dosis", sans-serif;
  color: #212121;
  font-size: 20px;
  font-weight: 700;
}
.tab.quote-list-tab .tabs li span {
  color: #1dd3f8;
  padding: 10px 20px;
  cursor: pointer;
  display: inline-block;
}
.tab.quote-list-tab .tabs .current {
  background-color: #1dd3f8;
  border-radius: 10px;
}
.tab.quote-list-tab .tabs .current span {
  color: #ffffff;
}
/*
 Newsletter Area Style
 ======================================================*/
.newsletter-area {
  background-color: #060542;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.newsletter-area .newsletter-shape img {
  position: absolute;
}
.newsletter-area .newsletter-shape img:nth-child(1) {
  bottom: -40px;
  left: -25px;
  max-width: 260px;
  z-index: -1;
  opacity: 0.5;
}
.newsletter-area .newsletter-shape img:nth-child(2) {
  bottom: -40px;
  right: -25px;
  max-width: 260px;
  z-index: -1;
  opacity: 0.5;
}
.newsletter-area .newsletter-shape img:nth-child(3) {
  top: 0;
  right: 0;
  z-index: -1;
  opacity: 0.5;
}
.newsletter-wrap h2 {
  font-size: 35px;
  margin-bottom: 30px;
  color: #ffffff;
}
.newsletter-wrap .newsletter-form {
  position: relative;
}
.newsletter-wrap .newsletter-form .form-control {
  border-radius: 10px;
  background-color: #ffffff;
}
.newsletter-wrap .newsletter-form .default-btn {
  position: absolute;
  top: 0;
  right: 0;
}
.newsletter-wrap .newsletter-form #validator-newsletter {
  color: #dc3545;
  margin-top: 10px;
}
.call-us h2 {
  font-size: 35px;
  margin-bottom: 30px;
  color: #ffffff;
}
.call-us span {
  font-size: 40px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
}
.call-us span:hover {
  color: #1dd3f8;
}
/*
 Industries Serve Area Style
 ======================================================*/
.industries-serve-area {
  
}
.industries-serve-area .industries-img {
  text-align: center;
}
.single-industries {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  border-radius: 60px 0 0 60px;
  transition: all 0.6s ease;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-left: 90px;
  padding-right: 30px;
  padding-bottom: 30px;
}
.single-industries.right-item {
  border-radius: 0 60px 60px 0;
}
.single-industries.right-item::before {
  border-radius: 0 60px 60px 0;
}
.single-industries.right-item:hover::before {
  border-radius: 0 60px 60px 0;
}
.single-industries::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #1dd3f8;
  z-index: -1;
  border-radius: 60px 0 0 60px;
  transition: all 0.6s ease;
}
.single-industries i {
  position: absolute;
  left: 30px;
  top: 35px;
  font-size: 40px;
  color: #1dd3f8;
  line-height: 1;
  transition: all 0.6s ease;
}
.single-industries .right-icon {
  position: absolute;
  right: 23px;
  top: 42px;
  font-size: 30px;
}
.single-industries .right-icon span {
  color: #e7e8e8;
}
.single-industries .down-icon {
  position: absolute;
  right: calc(50% - 15px);
  font-size: 30px;
}
.single-industries .down-icon span {
  color: #e7e8e8;
}
.single-industries h3 {
  transition: all 0.6s ease;
}
.single-industries span {
  margin-bottom: -3px;
  display: block;
  font-weight: 600;
  transition: all 0.6s ease;
  color: #4d4d4d;
}
.single-industries:hover i {
  color: #ffffff;
  transform: rotate(360deg);
}
.single-industries:hover h3 {
  color: #ffffff;
}
.single-industries:hover span {
  color: #ffffff;
}
.single-industries:hover a {
  right: 20px;
}
.single-industries:hover::before {
  width: 100%;
  border-radius: 60px 0 0 60px;
}
/*
 Brand Area Serve Area Style
 ======================================================*/
.brand-slide .swiper-button-next:after, .brand-slide .swiper-button-prev:after {
  font-size: 25px;
  color: #1dd3f8;
}
.brand-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.brand-list .single-brand {
  flex: 0 0 24%;
  max-width: 20%;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  margin-bottom: 50px;
}
.brand-list .single-brand a {
  display: block;
  text-align: center;
  position: relative;
  transition: all 0.6s ease;
}
/*====================================================
 HOME THREE STYLE
 ======================================================*/
/*
 Banner Three Area Style
 ======================================================*/
.main-banner-area-three {
  padding-bottom: 250px;
  overflow: hidden;
}
.main-banner-area-three .banner-text {
  top: 20px;
}
.main-banner-area-three .banner-text .banner-btn .default-btn.active {
  padding-left: 52px;
}
.main-banner-area-three .banner-text .banner-btn .default-btn.active i {
  position: absolute;
  font-size: 25px;
  top: 14px;
  left: 28px;
}
.main-banner-area-three .banner-img {
  position: relative;
  z-index: 1;
  top: 0;
}
.main-banner-area-three .banner-img img {
  position: absolute;
}
.main-banner-area-three .banner-img img:nth-child(1) {
  bottom: -440px;
  right: 0;
  left: 0;
  margin: auto;
}
.main-banner-area-three .banner-img img:nth-child(2) {
  top: -45px;
  left: 0;
  right: 0;
  margin: auto;
}
.main-banner-area-three .whites-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.main-banner-area-three .over-shape img {
  position: absolute;
  z-index: -1;
}
.main-banner-area-three .over-shape img:nth-child(1) {
  top: 300px;
  left: 40px;
  animation: animationFramesOne 25s infinite linear;
}
.main-banner-area-three .over-shape img:nth-child(2) {
  top: 200px;
  left: 800px;
  animation: animationFramesOne 40s infinite linear;
}
.main-banner-area-three .over-shape img:nth-child(3) {
  top: 150px;
  right: 800px;
  animation: animationFramesOne 20s infinite linear;
}
.main-banner-area-three .over-shape img:nth-child(4) {
  bottom: 200px;
  right: 800px;
  animation: animationFramesOne 30s infinite linear;
}
.main-banner-area-three .over-shape img:nth-child(5) {
  top: 200px;
  right: 200px;
  animation: animationFramesOne 10s infinite linear;
}
/*
 Brand Three Area Style
 ======================================================*/
.brand-area-two {
  background-color: #f0fcfe;
}
/*
 Case Area Style
 ======================================================*/
.case-area .swiper-pagination {
  position: initial;
  margin-top: 30px;
  line-height: 1;
}
.case-area .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 8px;
  background: #d6d6d6;
  border-radius: 30px;
  opacity: 1;
  transition: all 0.6s ease;
}
.case-area .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active, .case-area .swiper-pagination .swiper-pagination-bullet:hover {
  background: #1dd3f8;
}
.case-wrap {
  text-align: center;
}
.case-wrap .single-case {
  position: relative;
  transition: all 0.6s ease;
  overflow: hidden;
  margin-bottom: 20px;
  z-index: 1;
}
.case-wrap .single-case img {
  border-radius: 30px;
}
.case-wrap .single-case::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #1dd3f8;
  opacity: 0.9;
  transition: all 0.6s ease;
  border-radius: 30px;
  z-index: 5;
}
.case-wrap .single-case .link-icon {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: all 0.6s ease;
  z-index: 10;
}
.case-wrap .single-case .link-icon i {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  text-align: center;
  font-size: 35px;
  color: #ffffff;
}
.case-wrap .single-case .link-icon i:hover {
  background-color: #000000;
  color: #ffffff;
  border: 2px solid #000000;
}
.case-wrap:hover .single-case::before {
  left: 0;
}
.case-wrap:hover .single-case .link-icon {
  top: 0;
}
.case-wrap h3 {
  margin-bottom: 0;
}
.case-wrap h3 a {
  color: #212121;
}
.case-wrap h3 a:hover {
  color: #1dd3f8;
}
.case-wrap.case-style-2 h3 a {
  color: #fff;
}
.case-wrap.case-style-2 h3 a:hover {
  text-decoration: underline;
}
/*
 FAQ CSS
 ======================================================*/
.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  border: none;
}
.faq-accordion .accordion .accordion__item {
  margin-bottom: 15px;
  border: none;
}
.faq-accordion .accordion .accordion__item:hover {
  transform: translateY(-5px);
}
.faq-accordion .accordion .accordion__item:last-child {
  margin-bottom: 0;
}
.faq-accordion .accordion .accordion__button {
  border-radius: 5px;
  display: block;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
  background: #ffffff;
  padding: 20px 25px;
  color: #212121;
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.faq-accordion .accordion .accordion__button:focus {
  outline: 0;
}
.faq-accordion .accordion .accordion__button i {
  position: absolute;
  right: 25px;
  top: 18px;
  font-size: 25px;
  transition: 0.5s;
}
.faq-accordion .accordion .accordion-title.active i {
  transform: rotate(180deg);
}
.faq-accordion .accordion .accordion__panel {
  background-color: #ffffff;
  position: relative;
  padding-bottom: 20px;
  padding-right: 60px;
  padding-left: 25px;
  box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
}
.faq-accordion .accordion .accordion__panel p {
  line-height: 1.8;
}
.faq-accordion .accordion .accordion-content.show {
  display: block;
}
/*====================================================
 HOME FOUR STYLE
 ======================================================*/
/*
 Banner Four Area Style
 ======================================================*/
.main-banner-area-four {
  padding-bottom: 170px;
  overflow: hidden;
}
.main-banner-area-four .banner-text {
  top: 30px;
  padding-right: 30px;
}
.main-banner-area-four .banner-text .banner-btn .default-btn.active {
  padding-left: 52px;
}
.main-banner-area-four .banner-text .banner-btn .default-btn.active i {
  position: absolute;
  font-size: 25px;
  top: 14px;
  left: 28px;
}
.main-banner-area-four .over-shape img {
  position: absolute;
  z-index: -1;
}
.main-banner-area-four .over-shape img:nth-child(1) {
  top: 300px;
  left: 40px;
  animation: animationFramesOne 25s infinite linear;
}
.main-banner-area-four .over-shape img:nth-child(2) {
  top: 200px;
  left: 800px;
  animation: animationFramesOne 40s infinite linear;
}
.main-banner-area-four .over-shape img:nth-child(3) {
  top: 150px;
  right: 800px;
  animation: animationFramesOne 20s infinite linear;
}
.main-banner-area-four .over-shape img:nth-child(4) {
  bottom: 200px;
  right: 800px;
  animation: animationFramesOne 30s infinite linear;
}
.main-banner-area-four .over-shape img:nth-child(5) {
  top: 200px;
  right: 200px;
  animation: animationFramesOne 10s infinite linear;
}
/*
 Home Four Section Title Color Area Style
 ======================================================*/
.home-four-section-title h2 {
  color: #ffffff;
}
.home-four-section-title p {
  color: #e7e7e7;
}
/*
 About Area Four Style
 ======================================================*/
.about-area-four .about-content h2 {
  color: #ffffff;
}
.about-area-four .about-content p {
  color: #e7e7e7;
}
.about-area-four .about-content ul li {
  color: #e7e7e7;
}
/*
 Business Area Four Style
 ======================================================*/
.business-area-four .business-content h2 {
  color: #ffffff;
}
.business-area-four .single-business h3 {
  color: #ffffff;
}
.business-area-four .single-business p {
  color: #e7e7e7;
}
/*
 Offer Area Four Style
 ======================================================*/
.offer-area-four {
  background-color: #060542;
}
.offer-area-four .offer-shape img:nth-child(1) {
  opacity: 0.3;
}
.offer-area-four .offer-shape img:nth-child(2) {
  opacity: 0.3;
}
/*
 Industries Area Four Style
 ======================================================*/
.industries-area-four .industries-content h3 {
  color: #ffffff;
}
.industries-area-four .industries-content p {
  color: #e7e7e7;
}
/*
 Team Area Four Style
 ======================================================*/
.single-team {
  text-align: center;
  padding: 30px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  transition: all 0.6s ease;
  background-color: #fff;
}
.single-team .team-single-img {
  position: relative;
  margin-top: 15px;
  margin-bottom: 55px;
}
.single-team .team-single-img img {
  border-radius: 50%;
}
.single-team .team-single-img .team-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  animation: rotation 20s linear infinite;
}
.single-team .team-content h3 {
  color: #212121;
}
.single-team .team-content span {
  margin-bottom: 15px;
  display: block;
}
.single-team .team-content ul {
  line-height: 1;
  padding: 0;
  margin: 0;
}
.single-team .team-content ul li {
  display: inline-block;
  padding: 0 5px;
}
.single-team .team-content ul li a {
  background-color: #fafafa;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.single-team .team-content ul li a:hover {
  animation: rubberBand 1s linear;
}
/*====================================================
 HOME FIVE STYLE
 ======================================================*/
/*
 jumpx SliderArea Style
 ======================================================*/
.hero-swiper .swiper-button-next, .hero-swiper .swiper-button-prev {
  width: auto;
}
.hero-swiper .swiper-button-next:after, .hero-swiper .swiper-button-prev:after {
  background-color: #1dd3f8;
  color: #ffffff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  text-align: center;
  transition: all 0.6s ease;
  font-size: 22px;
  border-radius: 30px;
}
.hero-swiper .swiper-button-next:after:hover, .hero-swiper .swiper-button-prev:after:hover {
  background-color: #000;
  color: #ffffff;
}
.jumpx-slider-item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  z-index: 1;
}
.jumpx-slider-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #24416b;
  z-index: -1;
  opacity: 0.9;
}
.jumpx-slider-item .jumpx-slider-text {
  text-align: center;
  max-width: 750px;
  margin: 50px auto 0;
}
.jumpx-slider-item .jumpx-slider-text span {
  color: #ffffff;
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
.jumpx-slider-item .jumpx-slider-text h1 {
  font-size: 70px;
  color: #ffffff;
  margin-bottom: 30px;
}
.jumpx-slider-item .jumpx-slider-text p {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 45px;
}
.jumpx-slider-item .jumpx-slider-text .slider-btn .default-btn {
  margin: 0 20px;
}
.jumpx-slider-item .jumpx-slider-text .slider-btn .default-btn.white {
  color: #ffffff;
}
/*==================================================== 
 INNER PAGE STYLE
 ======================================================*/
/* 
 Page Title Area Style
 =====================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  padding-top: 200px;
  padding-bottom: 150px;
  background-color: #04063c;
  overflow: hidden;
  text-align: center;
}
.page-title-area .page-title-content h2 {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 700;
}
.page-title-area .page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.page-title-area .page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 10px;
  color: #1dd3f8;
}
.page-title-area .page-title-content ul li::before {
  content: "";
  position: absolute;
  top: 12px;
  right: -3px;
  background-color: #1dd3f8;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.page-title-area .page-title-content ul li:last-child::before {
  display: none;
}
.page-title-area .page-title-content ul li a {
  color: #ffffff;
}
.page-title-area .page-title-content ul li a:hover {
  color: #1dd3f8;
}
/*
 404 Error Area Style
 =====================================================*/
.error-area {
  padding: 50px 0;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
}
.error-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: -1;
}
.error-area .error-content-wrap {
  z-index: 1;
  position: relative;
}
.error-area .error-content-wrap h1 {
  font-size: 300px;
  line-height: 1;
}
.error-area .error-content-wrap h1 span {
  background-image: linear-gradient(to bottom right, #ff416c, #fa4612);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.error-area .error-content-wrap h3 {
  margin: 30px 0 0;
  position: relative;
  color: #ff0000;
  font-size: 30px;
}
.error-area .error-content-wrap p {
  margin: 20px 0 20px;
  font-size: 19px;
}
/* 
 Coming Soon Area Style
 =====================================================*/
.coming-soon-area {
  position: relative;
  height: 100vh;
  z-index: 1;
  background-color: #00095e;
}
.coming-soon-area .coming-soon-content {
  text-align: center;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}
.coming-soon-area .coming-soon-content h1 {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 60px;
  margin-top: -18px;
}
.coming-soon-area .coming-soon-content p {
  color: #f3f3f3;
  font-size: 14.5px;
  max-width: 600px;
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-area .coming-soon-content #timer {
  margin-top: 30px;
}
.coming-soon-area .coming-soon-content #timer div {
  display: inline-block;
  color: #ffffff;
  position: relative;
  margin-left: 35px;
  margin-right: 35px;
  font-size: 45px;
  font-weight: 700;
}
.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  text-transform: capitalize;
  margin-top: -15px;
  font-size: 16px;
  font-weight: normal;
}
.coming-soon-area .coming-soon-content #timer div:last-child {
  margin-right: 0;
}
.coming-soon-area .coming-soon-content #timer div:last-child::before {
  display: none;
}
.coming-soon-area .coming-soon-content #timer div:first-child {
  margin-left: 0;
}
.coming-soon-area .coming-soon-content #timer div::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -10px;
  font-size: 70px;
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter {
  display: block;
  width: 100%;
  height: 60px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  padding-left: 15px;
  color: #ffffff;
  outline: 0;
  transition: 0.5s;
  border-radius: 10px;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter::placeholder {
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .newsletter-form .input-newsletter:focus {
  border-color: #1dd3f8;
}
.coming-soon-area .coming-soon-content .newsletter-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  padding: 0 30px;
  text-transform: uppercase;
  outline: 0;
  color: #ffffff;
  transition: 0.5s;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}
.coming-soon-area .coming-soon-content .newsletter-form button::after {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button::before {
  border-radius: 0;
}
.coming-soon-area .coming-soon-content .newsletter-form button:hover {
  color: #ffffff;
}
.coming-soon-area .coming-soon-content .newsletter-form #validator-newsletter {
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  margin: 0 auto;
}
/*
 Sign In & Log In  Area Style
 =====================================================*/
.user-area-all-style {
  position: relative;
  z-index: 1;
}
.user-area-all-style .contact-form-action {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 40px;
  max-width: 570px;
  margin: auto;
}
.user-area-all-style .contact-form-action .form-heading {
  margin-bottom: 33px;
}
.user-area-all-style .contact-form-action .form-heading .form-title {
  font-size: 35px;
  color: #233d63;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.user-area-all-style .contact-form-action .form-heading .form-desc {
  font-size: 18px;
  color: #677286;
  font-weight: 500;
}
.user-area-all-style .contact-form-action .form-heading .reset-desc {
  line-height: 30px;
  margin-top: 15px;
}
.user-area-all-style .contact-form-action .form-heading .reset-desc a {
  color: #1dd3f8;
}
.user-area-all-style .contact-form-action .form-heading .reset-desc {
  font-size: 16px;
}
.user-area-all-style .contact-form-action .form-heading .reset-desc a {
  font-weight: 600;
}
.user-area-all-style .contact-form-action form .submit-btn {
  margin-top: 24px;
}
.user-area-all-style .contact-form-action form .default-btn {
  font-size: 14px;
  padding: 12px 20px;
}
.user-area-all-style .contact-form-action form .default-btn.btn-two {
  display: table;
  margin: 0px auto 0;
  padding: 20px 35px;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(1) .default-btn {
  background-color: #ff5733;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(2) .default-btn {
  background-color: #3955bc;
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.user-area-all-style .contact-form-action form .col-lg-4:nth-child(3) .default-btn {
  background-color: #1dd3f8;
  float: right;
}
.user-area-all-style .contact-form-action form .reset-btn {
  margin-top: 0;
}
.user-area-all-style .contact-form-action form .form-condition {
  margin-bottom: 20px;
}
.user-area-all-style .contact-form-action form .form-condition .agree-label {
  font-weight: 600;
}
.user-area-all-style .contact-form-action form .form-condition .agree-label a {
  color: #1dd3f8;
}
.user-area-all-style .contact-form-action form .form-group {
  margin-bottom: 30px;
}
.user-area-all-style .contact-form-action .account-desc {
  margin: 25px auto 0;
  display: table;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .account-desc a {
  color: #1dd3f8;
  margin-left: 10px;
  font-weight: 600;
}
.user-area-all-style .contact-form-action #chb1 {
  position: relative;
  top: 1px;
}
.user-area-all-style .contact-form-action #chb2 {
  position: relative;
  top: 1px;
}
.user-area-all-style .contact-form-action .forget {
  float: right;
  color: #1dd3f8;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .now-register {
  font-weight: 600;
  float: right;
  margin-bottom: 30px;
}
.user-area-all-style .contact-form-action .now-register a {
  color: #1dd3f8;
}
.user-area-all-style .contact-form-action .now-log-in {
  color: #1dd3f8;
  font-weight: 600;
}
.user-area-all-style .contact-form-action .now-log-in .font-q {
  font-weight: 600;
}
/*
 Ask Question Area Style
 =====================================================*/
.ask-question {
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  padding: 50px;
}
.ask-question .section-title {
  margin: -9px auto 40px;
}
.ask-question h3 {
  margin-bottom: 30px !important;
  text-align: center;
}
.ask-question .form-group {
  margin-bottom: 30px;
}
.ask-question .form-group .form-control {
  border-radius: 4px;
}
.ask-question .default-btn {
  margin: auto;
  display: table;
}
.list-unstyled {
  color: #dc3545 !important;
  margin-top: 10px;
}
#msgSubmit {
  position: absolute;
  bottom: 8px;
  right: 15px;
}
.text-danger {
  color: #dc3545 !important;
}
.text-success {
  color: #28a745 !important;
}
.inner-client-page {
  background-color: #f5f5f5 !important;
}
.inner-client-page .single-client {
  margin-bottom: 193px;
}
.inner-client-page .client-img h3 {
  color: #212121;
}
.inner-client-page .client-img span {
  color: #4d4d4d;
}
/*
 Ask Question Area Style
 =====================================================*/
.team-inner-area .single-team {
  background-color: #060542;
}
.team-inner-area .single-team:hover {
  transform: translateY(-5px);
}
/*
 Blog Details Area Style
 =====================================================*/
.news-details-area .blog-details-desc .article-image {
  position: relative;
  background-color: #f5f5f5;
}
.news-details-area .blog-details-desc .article-content {
  margin-top: 30px;
}
.news-details-area .blog-details-desc .article-content .entry-meta {
  margin-bottom: -10px;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: #1dd3f8;
  margin-right: 25px;
  font-size: 14px;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: #1dd3f8;
  font-weight: 500;
  margin-right: 5px;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #666666;
  font-size: 13px;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li a:hover {
  color: #1dd3f8;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li i {
  color: #1dd3f8;
  margin-right: 2px;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 13px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #1dd3f8;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.news-details-area .blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.news-details-area .blog-details-desc .article-content h3 {
  margin-bottom: 13px;
  margin-top: 25px;
}
.news-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.news-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.news-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.news-details-area .blog-details-desc .article-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.news-details-area .blog-details-desc .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
}
.news-details-area .blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: #1dd3f8;
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.news-details-area .blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #666666;
  font-weight: 600;
}
.news-details-area .blog-details-desc .article-footer .article-tags a:hover {
  color: #1dd3f8;
}
.news-details-area .blog-details-desc .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.news-details-area .blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.news-details-area .blog-details-desc .article-footer .article-share .social li {
  display: inline-block;
  margin-left: 5px;
}
.news-details-area .blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #666666;
}
.news-details-area .blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: #ffffff;
  width: 32px;
  height: 32px;
  line-height: 33px;
  border-radius: 50%;
  background-color: #1dd3f8;
  text-align: center;
  font-size: 14px;
}
.news-details-area .blog-details-desc .article-footer .article-share .social li a:hover {
  animation: rubberBand 1s linear;
}
.news-details-area .blog-details-desc .post-navigation {
  margin-top: 30px;
}
.news-details-area blockquote {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 50px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
.news-details-area blockquote p {
  color: #212121;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 17px !important;
}
.news-details-area blockquote cite {
  display: none;
}
.news-details-area blockquote::before {
  color: #1dd3f8;
  content: "\ed67";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "boxicons";
  font-size: 140px;
  font-weight: 900;
  opacity: 0.5;
}
.news-details-area blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #1dd3f8;
  margin-top: 20px;
  margin-bottom: 20px;
}
.news-details-area .post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.news-details-area .post-navigation .navigation-links {
  display: flex;
  flex-wrap: wrap;
}
.news-details-area .post-navigation .navigation-links .nav-previous {
  flex: 0 0 50%;
  max-width: 50%;
}
.news-details-area .post-navigation .navigation-links .nav-previous a i {
  margin-right: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.news-details-area .post-navigation .navigation-links .nav-next {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.news-details-area .post-navigation .navigation-links .nav-next a i {
  margin-left: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.news-details-area .post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 600;
  color: #212121;
}
.news-details-area .post-navigation .navigation-links div a:hover {
  color: #1dd3f8;
}
.news-details-area .comments-area {
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}
.news-details-area .comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 22px;
}
.news-details-area .comments-area .comments-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: #1dd3f8;
  top: 50%;
  transform: translateY(-50%);
}
.news-details-area .comments-area ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .comments-area .children {
  margin-left: 40px;
}
.news-details-area .comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.news-details-area .comments-area .comment-body .reply {
  margin-top: 15px;
}
.news-details-area .comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #212121;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 600;
}
.news-details-area .comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #1dd3f8;
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.news-details-area .comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.news-details-area .comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  border-radius: 50%;
}
.news-details-area .comments-area .comment-author .fn {
  font-weight: 600;
  color: #212121;
  font-size: 15px;
}
.news-details-area .comments-area .comment-author .says {
  display: none;
}
.news-details-area .comments-area .comment-metadata {
  color: #666666;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
.news-details-area .comments-area .comment-metadata a {
  color: #666666;
  font-size: 11px;
}
.news-details-area .comments-area .comment-metadata a:hover {
  color: #1dd3f8;
}
.news-details-area .comments-area .comment-content p {
  font-size: 14px;
}
.news-details-area .comments-area .comment-respond {
  margin-top: 30px;
}
.news-details-area .comments-area .comment-respond .comment-reply-title {
  margin-bottom: 15px;
  position: relative;
  font-size: 22px;
}
.news-details-area .comments-area .comment-respond .comment-reply-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: #1dd3f8;
  top: 50%;
  transform: translateY(-50%);
}
.news-details-area .comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.news-details-area .comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.news-details-area .comments-area .comment-respond .comment-form-comment {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
.news-details-area .comments-area .comment-respond label {
  display: block;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px;
}
.news-details-area .comments-area .comment-respond input[type="datetime-local"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="datetime-local"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="week"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="week"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="month"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="month"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="text"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="text"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="email"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="email"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="url"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="url"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="password"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="password"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="search"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="search"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="tel"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="tel"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond input[type="number"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond input[type="number"]:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .comments-area .comment-respond textarea:focus {
  border-color: #1dd3f8;
}
.news-details-area .comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.news-details-area .comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.news-details-area .comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.news-details-area .comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.news-details-area .comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.news-details-area .comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #666666;
  font-weight: normal;
}
.news-details-area .comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.news-details-area .comments-area .comment-respond .form-submit input {
  background: #1dd3f8;
  border: none;
  color: #ffffff;
  padding: 14px 30px 12px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 500;
  font-size: 14px;
}
.news-details-area .comments-area .comment-respond .form-submit input:hover {
  color: #ffffff;
  background-color: #212121;
}
.news-details-area .comments-area .comment-respond .form-submit input:focus {
  color: #ffffff;
  background-color: #1dd3f8;
  /*
   Sidebar Widget Area Style
   ================================*/
}
.news-details-area .widget-area .widget {
  margin-top: 35px;
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
}
.news-details-area .widget-area .widget .post-wrap {
  padding: 20px;
}
.news-details-area .widget-area .widget:first-child {
  margin-top: 0;
}
.news-details-area .widget-area .widget .widget-title {
  text-transform: capitalize;
  position: relative;
  font-size: 20px;
  margin-bottom: 0;
  background-color: #f7f7f7;
  padding: 10px 20px;
  color: #212121;
  position: relative;
}
.news-details-area .widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: #1dd3f8;
}
.news-details-area .widget-area .widget_search form {
  position: relative;
}
.news-details-area .widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.news-details-area .widget-area .widget_search form .screen-reader-text {
  display: none;
}
.news-details-area .widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.news-details-area .widget-area .widget_search form .search-field:focus {
  border-color: #1dd3f8;
}
.news-details-area .widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  border: none;
  color: #ffffff;
  background-color: #1dd3f8;
  transition: 0.5s;
  cursor: pointer;
}
.news-details-area .widget-area .widget_search form button:hover {
  background-color: #212121;
}
.news-details-area .widget-area .widget-posts-thumb {
  position: relative;
  overflow: hidden;
}
.news-details-area .widget-area .widget-posts-thumb .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.news-details-area .widget-area .widget-posts-thumb .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.news-details-area .widget-area .widget-posts-thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.news-details-area .widget-area .widget-posts-thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: #212121;
}
.news-details-area .widget-area .widget-posts-thumb .item .info {
  overflow: hidden;
}
.news-details-area .widget-area .widget-posts-thumb .item .info span {
  display: block;
  color: #666666;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}
.news-details-area .widget-area .widget-posts-thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}
.news-details-area .widget-area .widget-posts-thumb .item .info .title a {
  display: inline-block;
  color: #474c40;
}
.news-details-area .widget-area .widget-posts-thumb .item .info .title a:hover {
  color: #1dd3f8;
}
.news-details-area .widget-area .widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 17px;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.news-details-area .widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.news-details-area .widget-area .widget_recent_entries ul li::before {
  background: #1dd3f8;
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
}
.news-details-area .widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}
.news-details-area .widget-area .widget_categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .widget-area .widget_categories ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 17px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
}
.news-details-area .widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.news-details-area .widget-area .widget_categories ul li::before {
  background: #1dd3f8;
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 8px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.news-details-area .widget-area .widget_categories ul li a {
  display: block;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}
.news-details-area .widget-area .widget_categories ul li a span {
  float: right;
  font-size: 13px;
}
.news-details-area .widget-area .widget_categories ul li a:hover {
  color: #1dd3f8;
}
.news-details-area .widget-area .widget_categories ul li .post-count {
  float: right;
}
.news-details-area .widget-area .widget_meta ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.news-details-area .widget-area .widget_meta ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 17px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
}
.news-details-area .widget-area .widget_meta ul li a {
  color: #4d4d4d;
}
.news-details-area .widget-area .widget_meta ul li a:hover {
  color: #1dd3f8;
}
.news-details-area .widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.news-details-area .widget-area .widget_meta ul li::before {
  background: #1dd3f8;
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 8px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.news-details-area .widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 0;
}
.news-details-area .widget-area .tagcloud a {
  display: inline-block;
  color: #666666;
  font-weight: normal;
  font-size: 14px !important;
  padding: 7px 10px;
  border: 1px dashed #eeeeee;
  margin-top: 10px;
  margin-right: 10px;
}
.news-details-area .widget-area .tagcloud a:hover {
  background-color: #1dd3f8;
  color: #ffffff;
}
.news-details-area .widget-area .tagcloud a:focus {
  background-color: #1dd3f8;
  color: #ffffff;
  border-color: #1dd3f8;
}
.news-details-area .widget-area .widget_services_list ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: #ffffff;
}
.news-details-area .widget-area .widget_services_list ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.news-details-area .widget-area .widget_services_list ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.news-details-area .widget-area .widget_services_list ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.news-details-area .widget-area .widget_services_list ul li a:hover {
  background-color: #1dd3f8;
  color: #ffffff;
  padding-left: 20px;
}
.news-details-area .widget-area .widget_services_list ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.news-details-area .widget-area .widget_services_list ul li a.active {
  background-color: #1dd3f8;
  color: #ffffff;
  padding-left: 20px;
}
.news-details-area .widget-area .widget_services_list ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.news-details-area .widget-area .widget_services_list ul li:last-child a {
  border-bottom: none;
}
.news-details-area .widget-area .widget_download ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: #ffffff;
}
.news-details-area .widget-area .widget_download ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.news-details-area .widget-area .widget_download ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.news-details-area .widget-area .widget_download ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.news-details-area .widget-area .widget_download ul li a:hover {
  background-color: #1dd3f8;
  color: #ffffff;
  padding-left: 20px;
}
.news-details-area .widget-area .widget_download ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.news-details-area .widget-area .widget_download ul li a.active {
  background-color: #1dd3f8;
  color: #ffffff;
  padding-left: 20px;
}
.news-details-area .widget-area .widget_download ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.news-details-area .widget-area .widget_download ul li:last-child a {
  border-bottom: none;
}
.news-details-area .widget-area .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}
/*
 Contact Info Area Style 
 ================================*/
.contact-info-area .single-contact-info {
  text-align: center;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px;
  transition: all 0.5s;
  border-radius: 4px;
}
.contact-info-area .single-contact-info i {
  display: block;
  color: #1dd3f8;
  transition: all 0.5s;
  font-size: 25px;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
  line-height: 50px;
  border: 2px dotted #1dd3f8;
  border-radius: 50%;
}
.contact-info-area .single-contact-info h3 {
  transition: all 0.5s;
}
.contact-info-area .single-contact-info p {
  margin: 0;
}
.contact-info-area .single-contact-info a {
  color: #4d4d4d;
  transition: all 0.5s;
}
.contact-info-area .single-contact-info:hover {
  background-color: #1dd3f8;
}
.contact-info-area .single-contact-info:hover i {
  color: #ffffff;
  border-color: #ffffff;
}
.contact-info-area .single-contact-info:hover h3 {
  color: #ffffff;
}
.contact-info-area .single-contact-info:hover p {
  color: #ffffff;
}
.contact-info-area .single-contact-info:hover a {
  color: #ffffff;
}
#map {
  height: 445px;
}
/*
 Main Contact Area Style
 =====================================================*/
.main-contact-area .contact-wrap {
  max-width: unset;
}
.main-contact-area .contact-wrap .section-title {
  text-align: center !important;
  max-width: 600px;
}
.main-contact-area .contact-wrap .section-title h2 {
  margin-bottom: 10px;
}
.main-contact-area .contact-wrap .form-group {
  margin-bottom: 30px;
}
.main-contact-area .contact-wrap .default-btn {
  margin: auto;
  display: table;
  cursor: pointer;
}
.main-contact-area .contact-wrap .contact-form {
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 50px;
  border-radius: 4px;
}
.main-contact-area .contact-wrap .contact-form #msgSubmit {
  position: unset;
  text-align: center;
  margin-top: 20px;
}
.main-contact-area .contact-wrap .contact-form .hidden {
  display: none;
}
.main-contact-area .contact-wrap .form-control {
  border-radius: 4px !important;
}
/*
 Contact Info Area Three Style
 =====================================================*/
.contact-info-three .contact-wrap .contact-form {
  padding: 48px;
}
.features-area-inner-style .single-features {
  margin-bottom: 40px;
}
/*
 Service Details Area Style
 =====================================================*/
.service-sidebar-area .service-details-title {
  background-color: #eeeeee;
  color: #212121;
  padding: 15px 20px;
  margin-bottom: 0;
}
.service-sidebar-area .service-card {
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}
.service-sidebar-area .service-list {
  margin-bottom: 30px;
}
.service-sidebar-area .service-list:last-child {
  margin-bottom: 0;
}
.service-sidebar-area .service-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.service-sidebar-area .service-list ul li {
  border-bottom: 1px dashed #eeeeee;
  padding: 15px 20px;
  font-weight: 500;
  transition: all 0.5s;
}
.service-sidebar-area .service-list ul li a {
  color: #212121;
  display: block;
}
.service-sidebar-area .service-list ul li i {
  float: right;
  width: 27px;
  height: 27px;
  line-height: 27px;
  background-color: #1dd3f8;
  border-radius: 50%;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  transition: all 0.5s;
}
.service-sidebar-area .service-list ul li:hover {
  background-color: #1dd3f8;
  color: #ffffff;
}
.service-sidebar-area .service-list ul li:hover i {
  background-color: #ffffff;
  color: #1dd3f8;
}
.service-sidebar-area .service-list ul li:hover a {
  color: #ffffff;
}
.service-sidebar-area .service-faq .faq-accordion .accordion .accordion-item {
  box-shadow: none;
}
.service-sidebar-area .service-faq .faq-accordion .accordion .accordion-title {
  font-size: 17px;
  font-weight: 600;
}
.service-sidebar-area .service-faq .faq-accordion .accordion .accordion-title span {
  top: 11px;
}
.service-sidebar-area .paint {
  margin-bottom: 15px;
}
.service-details-wrap .mb-30 {
  margin-bottom: 30px;
}
.service-details-wrap .service-img {
  margin-bottom: 30px;
}
.service-details-wrap h3 {
  margin-bottom: 20px;
  font-size: 28px;
}
.service-details-wrap ul {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 0;
}
.service-details-wrap ul li {
  display: inline-block;
  font-weight: 600;
  color: #212121;
  font-size: 14px;
  padding-left: 30px;
  position: relative;
}
.service-details-wrap ul li i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #1dd3f8;
  border-radius: 50%;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: 5px;
  position: absolute;
  top: 0px;
  left: 0;
}
.service-details-wrap ul .paint {
  margin-right: 30px;
}
.service-details-wrap .car-service-list-wrap {
  margin-bottom: 35px;
  margin-top: 25px;
}
.service-details-wrap .car-service-list-wrap .car-service-list ul {
  margin: 0;
}
.service-details-wrap .car-service-list-wrap .car-service-list ul li {
  margin-bottom: 15px;
  display: block;
}
.service-details-wrap .car-service-list-wrap .car-service-list ul li:last-child {
  margin-bottom: 0;
}
.service-details-wrap .list-unstyled {
  margin: 0;
}
.service-details-wrap .list-unstyled li {
  color: #dc3545;
  padding-left: 0 !important;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}
.service-details-wrap #msgSubmit {
  top: 60px;
  left: 0;
  right: 0;
  text-align: center;
}
/* Video modal */
.modal-video-close-btn {
  cursor: pointer;
}
/* Text container */
.text-container h1 {
  font-size: 40px;
  margin-bottom: 15px;
}
.text-container h2 {
  font-size: 35px;
  margin-bottom: 15px;
}
.text-container h3 {
  font-size: 30px;
  margin-bottom: 15px;
}
.text-container h4 {
  font-size: 25px;
  margin-bottom: 15px;
}
.text-container h5 {
  font-size: 22px;
  margin-bottom: 15px;
}
.text-container h6 {
  font-size: 20px;
  margin-bottom: 15px;
}
.cta-area {
  background-image: url(./images/cta-bg.jpg);
  background-position: 50%;
  background-size: cover;
  text-align: center;
  position: relative;
  background-attachment: fixed;
}
.cta-area:before {
  background: linear-gradient(to left top, #ff2c9c, #ff287b, #ff395a, #ff5137, #ff6a00);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  opacity: 0.95;
}
.cta-area .cta-content {
  position: relative;
  margin-bottom: 30px;
}
.cta-area .cta-content span {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
}
.cta-area .cta-content h3 {
  color: #fff;
  font-size: 55px;
}
.form-check .form-check-input {
  margin-top: 5px;
}

.menu-area {
  margin-top: 100px;
}